import React, { useState } from 'react';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import styled from 'styled-components';
import { Button, palette, Spinner } from 'workmotion-design-system';

import { useUploadDocument } from 'helpers/use-upload-document';
import { en } from 'localization';
import { DocumentType } from 'types/contractor';

const maxSize = 10485760;

interface UploadDocumentProps {
  formDataFileAttributeName?: string;
  docOwnerId: string;
  filesExtensions: string[];
  documentType: DocumentType;
  CustomButton?: React.FC<any>;
}

const StyledUploadContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
});

const StyledFieldError = styled.span({
  fontSize: '.75rem',
  color: palette.semantic.red,
  marginTop: '-0.5rem',
  marginBottom: '-1.75rem',
});

const {
  contractor: {
    viewContractor: {
      documents: { uploadAction },
    },
  },
} = en;

export const UploadDocument: React.FC<UploadDocumentProps> = props => {
  const { docOwnerId, filesExtensions, documentType, CustomButton } = props;

  const [errors, setErrors] = useState<string>();

  const { rootProps, inputProps, isUploading } = useUploadDocument(
    docOwnerId,
    filesExtensions,
    documentType,
    maxSize,
    setErrors
  );

  return (
    <StyledUploadContainer>
      <Button
        {...rootProps}
        disabled={isUploading}
        btnType="transparent"
        size="small"
      >
        <input {...inputProps} />

        {isUploading ? (
          <Spinner size={30} />
        ) : CustomButton ? (
          <CustomButton />
        ) : (
          <Button
            style={{
              fontSize: '0.75rem',
              fontFamily: 'Work Sans',
              fontWeight: 500,
            }}
            onClick={() => {}}
            data-testid="confirm-btn"
            prefixIcon={() => <FileUploadIcon style={{ fontSize: '1rem' }} />}
            btnType="transparent"
          >
            {uploadAction}
          </Button>
        )}

        {errors && <StyledFieldError>{errors}</StyledFieldError>}
      </Button>
    </StyledUploadContainer>
  );
};
