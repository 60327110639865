import { useCallback } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { handleRequestError } from 'helpers/handle-request-error';
import { useAccessToken } from 'helpers/use-access-token';
import { en } from 'localization';
import { DocumentUploadDTO } from 'types/contractor';
import {
  AdjustInvoiceRequest,
  UpdateInvoiceStatusRequest,
} from 'types/invoice-submission';
import {
  SubmitHoursRequest,
  UpdateMilestonesStatusRequest,
  UpdateWorkStatementStatusRequest,
  WorkStatementIdAssigncontractorBody,
  WorkStatementRequest,
} from 'types/work-statement';

import {
  adjustInvoiceById,
  assignContractorToWorkStatement,
  createWorkStatement,
  deleteContractorClient,
  submitHours,
  updateInvoiceStatus,
  updateMilestonesStatus,
  updateWorkStatement,
  updateWorkStatementStatus,
  uploadContractorDocument,
} from './contractor-client';

const {
  workStatement: {
    createWorkStatement: { somethingWentWrong },
  },
} = en;

export const useCreateWorkStatement = () => {
  const { accessToken } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async (data: WorkStatementRequest) =>
      createWorkStatement(data, accessToken?.accessToken),
    [accessToken]
  );

  return useMutation({
    mutationFn,
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['WorkStatementsRecords']);
    },
  });
};

export const useUpdateWorkStatement = workStatementId => {
  const { accessToken } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async (data: WorkStatementRequest) =>
      updateWorkStatement({ workStatementId }, data, accessToken?.accessToken),
    [accessToken?.accessToken, workStatementId]
  );

  return useMutation({
    mutationFn,
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['WorkStatementsRecords']);
      queryClient.invalidateQueries(['getWorkStatementById']);
    },
  });
};

export const useAssignContractorToWorkStatement = workStatementId => {
  const { accessToken } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async (data: WorkStatementIdAssigncontractorBody) =>
      assignContractorToWorkStatement(
        { workStatementId },
        data,
        accessToken?.accessToken
      ),
    [accessToken?.accessToken, workStatementId]
  );

  return useMutation({
    mutationFn,
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['WorkStatementsRecords']);
      queryClient.invalidateQueries(['getWorkStatementById']);
    },
  });
};

export const useDeleteContractor = () => {
  const { accessToken } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async (contractorId: string) =>
      deleteContractorClient(accessToken?.accessToken, contractorId),
    [accessToken?.accessToken]
  );

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries(['getAllContractors']);

      queryClient.invalidateQueries(['getSearchContractors']);
    },
  });
};

export const useUpdateWorkStatementStatus = workStatementId => {
  const { accessToken } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async (data: UpdateWorkStatementStatusRequest) =>
      updateWorkStatementStatus(
        { workStatementId },
        data,
        accessToken?.accessToken
      ),
    [accessToken?.accessToken, workStatementId]
  );

  return useMutation({
    mutationFn,
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['WorkStatementsRecords']);
      queryClient.invalidateQueries(['getWorkStatementById']);
    },
  });
};

export const useUpdateMilestonesStatus = () => {
  const { accessToken } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async (data: UpdateMilestonesStatusRequest) =>
      updateMilestonesStatus(data, accessToken?.accessToken),
    [accessToken]
  );

  return useMutation({
    mutationFn,
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['WorkStatementsRecords']);
      queryClient.invalidateQueries(['getWorkStatementById']);
    },
  });
};

export const useSubmitHours = workStatementId => {
  const { accessToken } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async (data: SubmitHoursRequest) =>
      submitHours({ workStatementId }, data, accessToken?.accessToken),
    [accessToken?.accessToken, workStatementId]
  );

  return useMutation({
    mutationFn,
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['WorkStatementsRecords']);
      queryClient.invalidateQueries(['getWorkStatementById']);
    },
  });
};

export const useAdjustInvoiceById = invoiceId => {
  const { accessToken } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async (data: AdjustInvoiceRequest) =>
      adjustInvoiceById({ invoiceId }, data, accessToken?.accessToken),
    [accessToken?.accessToken, invoiceId]
  );

  return useMutation({
    mutationFn,
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['invoiceSubmissionRecords']);
      queryClient.invalidateQueries(['getInvoiceSubmissionById']);
    },
  });
};

export const useUploadContractorDocument = contractorId => {
  const { accessToken } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async (data: DocumentUploadDTO) =>
      uploadContractorDocument(accessToken?.accessToken, contractorId, data),
    [accessToken?.accessToken, contractorId]
  );

  return useMutation({
    mutationFn,
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['getContractor']);
    },
  });
};

export const useUpdateInvoiceStatus = invoiceId => {
  const { accessToken } = useAccessToken();

  const queryClient = useQueryClient();

  const mutationFn = useCallback(
    async (data: UpdateInvoiceStatusRequest) =>
      updateInvoiceStatus({ invoiceId }, data, accessToken?.accessToken),
    [accessToken?.accessToken, invoiceId]
  );

  return useMutation({
    mutationFn,
    onError: e => {
      handleRequestError(e, somethingWentWrong);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['invoiceSubmissionRecords']);
      queryClient.invalidateQueries(['getInvoiceSubmissionById']);
    },
  });
};
