import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const DrawerContainer = styled.div({
  padding: '2rem',
});

export const DrawerSection = styled.div({
  '&:not(:last-child)': {
    borderBottom: `1px solid ${palette.greyscale.antiFlashWhite}`,
    marginBottom: '1rem',
  },
});

export const ListItem = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1rem',
});

export const ListItemKey = styled.p({
  color: palette.greyscale.UCLABlue,
  fontSize: '.875rem',
  lineHeight: '1rem',
  fontWeight: 400,
});

export const ListItemValue = styled.b({
  color: palette.greyscale.persianBlue,
  fontSize: '.875rem',
  lineHeight: '1rem',
  fontWeight: 500,
  '&[data-is-amount="true"]': {
    color: palette.primary.genericViridian,
  },
});
