import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const StyledSingleCardContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  padding: '1rem',
  border: `0.0625rem solid ${palette.greyscale.antiFlashWhite}`,
  height: '8rem',
  borderRadius: '1rem',
  width: '100%',
  cursor: 'pointer',
  '@media(min-width:1200px)': {
    width: '48%',
  },
});

export const StyledRowContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

export const StyledIconContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '2.5rem',
  height: '2.5rem',
  background: palette.tertiary.aquaHaze,
  borderRadius: '0.75rem',
});

export const StyledTitle = styled.div({
  color: palette.greyscale.persianBlue,
  fontWeight: 600,
  fontSize: '1rem',
});

export const StyledSubtitle = styled.div({
  color: palette.greyscale.UCLABlue,
  fontWeight: 400,
  fontSize: '0.75rem',
});
