import { format } from 'date-fns';

export const camelizeString = (str: string): string =>
  str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (_m, chr) => chr.toUpperCase());

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const kebabCaseString = (string: string): string =>
  string
    ? string
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase()
    : undefined;

export const capitalizeFirstLetter = (string: string): string =>
  string && string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

// Display document subtitle i.e. : Feb 7, 2021 3:26PM . 1.4 Mb
export const getDocumentSubtitle = (date: string, size: number): string => {
  let string = '';

  if (date) string += `${format(new Date(date), 'MMM d, yyyy')} `;
  if (size) string += formatBytes(size);

  return string;
};
