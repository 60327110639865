import React from 'react';

import { useField } from 'formik';
import { FormField, InputField } from 'workmotion-design-system';

import { en } from 'localization';

const {
  invoiceSubmission: {
    adjustInvoice: {
      mileStoneTitle: { label, fieldName, placeHolder },
    },
  },
} = en;

const MilestoneTitle: React.FC = () => {
  const [field, meta, helpers] = useField(fieldName);

  return (
    <FormField
      label={label}
      showError={!!(meta.touched && meta.error)}
      errorMessage={meta.error}
    >
      <InputField
        {...field}
        type={'text'}
        placeholder={placeHolder}
        value={field.value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          helpers.setValue(event.target.value)
        }
        attrs={{
          input: {
            'data-testid': 'adjust-invoice-milestone-title-input',
            'data-cy': 'adjust-invoice-milestone-title-input',
          },
        }}
      />
    </FormField>
  );
};

export { MilestoneTitle };
