import React, { useCallback } from 'react';

import { Drawer } from 'workmotion-design-system';

import { formatDateInUTC } from 'helpers/get-date-utils';
import { formatNumber } from 'helpers/number-formatting';
import { en } from 'localization';
import { TransferPaymentStatus } from 'types/invoice-submission';

import { PreviewStatusLabel } from './status-label';
import {
  DrawerContainer,
  DrawerSection,
  ListItem,
  ListItemKey,
  ListItemValue,
} from './styled-components';

const dummyTransferHistory = [
  {
    status: 'PROCESSING',
    transactionDate: '2024-11-26',
    transactionId: 'WM-129348',
    totalAmount: 2500,
    currency: 'EUR',
  },
  {
    status: 'FAILED',
    transactionDate: '2024-11-26',
    transactionId: 'WM-129348',
    totalAmount: 2500,
    currency: 'EUR',
  },
  {
    status: 'TRANSFERRED',
    transactionDate: '2024-11-26',
    transactionId: 'WM-129348',
    totalAmount: 2500,
    currency: 'EUR',
  },
];

type TransferHistoryDrawerProps = {
  isTransferHistoryDrawerOpen: boolean;
  setIsTransferHistoryDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedInvoiceSubmission: string;
  setSelectedInvoiceSubmission: React.Dispatch<React.SetStateAction<string>>;
};

const {
  invoiceSubmission: {
    transferPaymentHistory: {
      title,
      fields: { status, transactionDate, transactionId, transactionAmount },
    },
  },
} = en;

const TransferHistoryDrawer: React.FC<TransferHistoryDrawerProps> = ({
  isTransferHistoryDrawerOpen,
  setIsTransferHistoryDrawerOpen,
  setSelectedInvoiceSubmission,
}) => {
  const handelCloseDrawer = useCallback(() => {
    setIsTransferHistoryDrawerOpen(false);
    setSelectedInvoiceSubmission(null);
  }, [setIsTransferHistoryDrawerOpen, setSelectedInvoiceSubmission]);

  return (
    <Drawer
      anchor="right"
      open={isTransferHistoryDrawerOpen}
      onClose={handelCloseDrawer}
      title={title}
    >
      <DrawerContainer data-testid="transfer-history-drawer">
        {dummyTransferHistory.map((transfer, index) => (
          <DrawerSection key={index}>
            <ListItem>
              <ListItemKey>{status}</ListItemKey>

              <PreviewStatusLabel
                status={transfer.status as TransferPaymentStatus}
              />
            </ListItem>

            <ListItem>
              <ListItemKey>{transactionDate}</ListItemKey>

              <ListItemValue>
                {formatDateInUTC(transfer.transactionDate, 'DATE')}
              </ListItemValue>
            </ListItem>

            <ListItem>
              <ListItemKey>{transactionId}</ListItemKey>

              <ListItemValue>{transfer.transactionId}</ListItemValue>
            </ListItem>

            <ListItem>
              <ListItemKey>{transactionAmount}</ListItemKey>

              <ListItemValue data-is-amount>
                {`${formatNumber(transfer.totalAmount)} ${transfer.currency}`}
              </ListItemValue>
            </ListItem>
          </DrawerSection>
        ))}
      </DrawerContainer>
    </Drawer>
  );
};

export default TransferHistoryDrawer;
