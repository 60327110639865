import * as ContractorApiClientTypes from 'networking/contractor-api/contractor-client-types';

export enum InvoiceSubmissionStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum LineItemTypesEnum {
  ALLOWANCE = 'ALLOWANCE',
  BONUS = 'BONUS',
  COMMISSION = 'COMMISSION',
}

export enum TransferPaymentStatus {
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  TRANSFERRED = 'TRANSFERRED',
}

export type InvoicesResponse =
  ContractorApiClientTypes.Components.Schemas.InvoicesResponse;

export type GetInvoiceByIdParameters =
  ContractorApiClientTypes.Paths.GetInvoiceById.PathParameters;

export type InvoicesDetailsResponse =
  ContractorApiClientTypes.Components.Schemas.InvoicesDetailsResponse;

export type AdjustInvoiceRequest =
  ContractorApiClientTypes.Components.Schemas.AdjustInvoiceRequest;

export type UpdateInvoiceStatusRequest =
  ContractorApiClientTypes.Components.Schemas.UpdateInvoiceStatusRequest;
