import React from 'react';

import { useField } from 'formik';
import { FormField, InputField } from 'workmotion-design-system';

import { en } from 'localization';

const {
  invoiceSubmission: {
    adjustInvoice: {
      amount: { placeHolder, label },
    },
  },
} = en;

const Amount: React.FC<{
  fieldName: string;
  currency: string;
}> = ({ fieldName, currency }) => {
  const [field, meta, helpers] = useField(fieldName);

  return (
    <FormField
      label={label}
      showError={!!(meta.touched && meta.error)}
      errorMessage={meta.error}
    >
      <InputField
        {...field}
        attrs={{
          input: {
            'data-testid': 'adjust-invoice-amount-input',
            'data-cy': 'adjust-invoice-amount-input',
          },
        }}
        placeholder={placeHolder}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          helpers.setValue(event.target.value)
        }
        unit={currency}
        type="unit"
        adornmentPosition="end"
      />
    </FormField>
  );
};

export { Amount };
