import React, { useCallback } from 'react';

import DrawRoundedIcon from '@mui/icons-material/DrawRounded';
import { toast } from 'react-toastify';
import { Modal, ModalButtonsFooter, Spinner } from 'workmotion-design-system';

import { en } from 'localization';
import { useUpdateInvoiceStatus } from 'networking/contractor-api/contractor-mutations';
import { InvoiceSubmissionStatus } from 'types/invoice-submission';

interface ModalProps {
  isReadyForReviewModalOpen: boolean;
  setIsReadyForReviewModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedInvoiceSubmission: string;
  setSelectedInvoiceSubmission: React.Dispatch<React.SetStateAction<string>>;
}

const {
  invoiceSubmission: {
    conformReadyForReviewModal: {
      title,
      modalDescription,
      submitButtonLabel,
      cancelButtonLabel,
      successMessage,
    },
  },
} = en;

const ConfirmReadyForReviewModal: React.FC<ModalProps> = ({
  isReadyForReviewModalOpen,
  setIsReadyForReviewModalOpen,
  selectedInvoiceSubmission,
  setSelectedInvoiceSubmission,
}) => {
  const { isLoading, mutate } = useUpdateInvoiceStatus(
    selectedInvoiceSubmission
  );

  const handelCloseModal = useCallback(() => {
    setIsReadyForReviewModalOpen(false);
    setSelectedInvoiceSubmission(null);
  }, [setIsReadyForReviewModalOpen, setSelectedInvoiceSubmission]);

  const handelConfirmation = useCallback(() => {
    mutate(
      {
        newStatus: InvoiceSubmissionStatus.PENDING,
      },
      {
        onSuccess: () => {
          toast(successMessage, {
            type: 'success',
          });

          handelCloseModal();
        },
      }
    );
  }, [handelCloseModal, mutate]);

  return (
    <Modal
      type="info"
      data-testid="confirm-ready-for-review-modal"
      isOpen={isReadyForReviewModalOpen}
      closeModal={handelCloseModal}
      footer={
        <ModalButtonsFooter
          placement="space-between"
          buttonsInfo={[
            {
              text: cancelButtonLabel,
              btnType: 'secondaryGrey',
              onClick: handelCloseModal,
              dataCy: 'cancel-confirm-ready-for-review-action',
            },
            {
              text: (
                <>{isLoading ? <Spinner size={30} /> : submitButtonLabel}</>
              ),
              btnType: 'default',
              type: 'submit',
              onClick: handelConfirmation,
              disabled: isLoading,
              dataCy: 'confirm-ready-for-review-action',
            },
          ]}
        />
      }
      defaultContent={{
        icon: <DrawRoundedIcon />,
        title,
        text: modalDescription,
      }}
      customContentStyle={{ whiteSpace: 'pre-wrap' }}
    />
  );
};

export default ConfirmReadyForReviewModal;
