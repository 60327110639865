import { TestContext } from 'yup';
import { AnyObject } from 'yup/lib/types';

import { ControlType, InputControlBase, InputType } from 'types/control-form';

const textInputMaxLength = (
    componentType: ControlType,
    val,
    { type }: InputControlBase
): boolean => {
    if (
        componentType === ControlType.INPUT &&
        type === InputType.TEXT &&
        val?.value
    ) {
        return String(val.value).length <= 255;
    }

    return true;
};

const inputTextMaxLength = (
    componentType: ControlType,
    val,
    { type }: InputControlBase,
    constarin: number
): boolean => {
    if (
        componentType === ControlType.INPUT &&
        type === InputType.TEXT &&
        val?.value &&
        constarin
    ) {
        return String(val.value).length <= constarin;
    }

    return true;
};

const inputTextMinLength = (
    componentType: ControlType,
    val,
    { type }: InputControlBase,
    constarin: number
): boolean => {
    if (
        componentType === ControlType.INPUT &&
        type === InputType.TEXT &&
        val?.value &&
        constarin
    ) {
        return String(val.value).length >= constarin;
    }

    return true;
};

const validNumber = (
    componentType: ControlType,
    val,
    { type }: InputControlBase,
    required: boolean
): boolean => {
    if (componentType === ControlType.INPUT && type === InputType.NUMBER) {
        if (!required && !val?.value) {
            return true;
        }

        return (
            !!Number(val?.value) ||
            Number(val?.value) === 0 ||
            (typeof val?.value === 'string' &&
                !(val?.value as string)?.includes('e'))
        );
    }

    return true;
};

const regexExpression = function (
    this: TestContext<AnyObject>,
    componentType: ControlType,
    val,
    component: InputControlBase
) {
    const regexConstraints = component.constraints
        ?.flatMap(item => item.regexes)
        .filter(item => !!item);

    if (
        componentType === ControlType.INPUT &&
        val?.value &&
        regexConstraints?.length
    ) {
        let regexError: string | undefined;

        regexConstraints.forEach(({ expression, message }) => {
            const stringValue: string = val.value && String(val.value);
            let testExpression = new RegExp(expression);

            if (expression.charAt(0) === '/' && expression.slice(-1) === '/') {
                // remove literal form if existing
                testExpression = new RegExp(
                    expression.substring(1, expression.length - 1)
                );
            }

            if (!stringValue?.match(testExpression) && !regexError) {
                regexError = message;
            }
        });

        return regexError ? this.createError({ message: regexError }) : true;
    }

    return true;
};

const minNumberValidation = (
    val,
    type: InputType,
    constraint: number
): boolean => {
    if (type === InputType.NUMBER && val?.value && constraint !== undefined) {
        return val?.value >= constraint;
    }

    return true;
};

const maxNumberValidation = (
    val,
    type: InputType,
    constraint: number
): boolean => {
    if (type === InputType.NUMBER && val?.value && constraint !== undefined) {
        return val?.value <= constraint;
    }

    return true;
};

const inputValidation = {
    textInputMaxLength,
    validNumber,
    regexExpression,
    minNumberValidation,
    maxNumberValidation,
    inputTextMaxLength,
    inputTextMinLength,
};

export { inputValidation };
