import React from 'react';

import { en } from 'localization';
import DocumentItem from 'shared-components/document-item';
import { InvoicesDetailsResponse } from 'types/invoice-submission';
import {
  DetailsModalField,
  DetailsModalLabel,
} from 'work-statement/styled-components';

const {
  invoiceSubmission: {
    invoiceDetailsModal: {
      fields: { invoice: invoiceField, otherFiles },
    },
  },
} = en;

const InvoiceDocuments: React.FC<{
  invoiceData: InvoicesDetailsResponse;
}> = ({ invoiceData }) => {
  const { invoice, optionalDocuments } = invoiceData;

  return (
    <>
      <DetailsModalField data-multi-rows data-with-top-border>
        <DetailsModalLabel data-multi-rows>{invoiceField}</DetailsModalLabel>

        <DocumentItem document={invoice} />
      </DetailsModalField>

      {!!optionalDocuments?.length && (
        <DetailsModalField data-multi-rows>
          <DetailsModalLabel data-multi-rows>{otherFiles}</DetailsModalLabel>

          {optionalDocuments.map(document => (
            <DocumentItem document={document} key={document.id} />
          ))}
        </DetailsModalField>
      )}
    </>
  );
};

export default InvoiceDocuments;
