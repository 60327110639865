import * as ContractorApiClientTypes from 'networking/contractor-api/contractor-client-types';

export type WorkStatementResponse =
  ContractorApiClientTypes.Components.Schemas.WorkStatementResponse;

export enum WorkStatementStatus {
  NEW = 'NEW',
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  SUBMITTED = 'SUBMITTED',
}

export enum WorkStatementPaymentType {
  FIXED_TERM = 'FIXED_TERM',
  HOURLY_BASED = 'HOURLY_BASED',
  PROJECT_BASED = 'PROJECT_BASED',
}

export enum PaymentFrequencyEnum {
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
  PER_MILESTONE = 'PER_MILESTONE',
  STANDALONE = 'STANDALONE',
}

export type WorkStatementRequest =
  ContractorApiClientTypes.Components.Schemas.WorkStatementRequest;

export type GetWorkStatementByIdParameters =
  ContractorApiClientTypes.Paths.GetWorkStatementById.PathParameters;

export type WorkStatementDetails =
  ContractorApiClientTypes.Components.Schemas.WorkStatementDetails;

export type Milestone =
  ContractorApiClientTypes.Components.Schemas.MilestoneResponse;

export type WorkStatementIdAssigncontractorBody =
  ContractorApiClientTypes.Components.Schemas.WorkStatementIdAssigncontractorBody;

export type MilestoneResponse =
  ContractorApiClientTypes.Components.Schemas.MilestoneResponse;

export type UpdateWorkStatementStatusRequest =
  ContractorApiClientTypes.Components.Schemas.UpdateWorkStatementStatusRequest;

export type UpdateMilestonesStatusRequest =
  ContractorApiClientTypes.Components.Schemas.UpdateMilestonesStatusRequest;

export type SubmitHoursRequest =
  ContractorApiClientTypes.Components.Schemas.SubmitHoursRequest;
