import React, { useCallback } from 'react';

import { useField } from 'formik';
import { FormField, InputField } from 'workmotion-design-system';

import { en } from 'localization';

const {
  invoiceSubmission: {
    adjustInvoice: {
      invoiceNumber: { placeHolder, label, fieldName },
    },
  },
} = en;

const InvoiceNumber = () => {
  const [field, meta, helpers] = useField(fieldName);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      helpers.setValue(event.target.value);
    },
    [helpers]
  );

  return (
    <FormField
      showError={!!(meta.touched && meta.error)}
      errorMessage={meta.error}
      label={label}
    >
      <InputField
        {...field}
        attrs={{
          input: {
            'data-testid': 'adjust-invoice-invoice-number-input',
            'data-cy': 'adjust-invoice-invoice-number-input',
          },
        }}
        type={'text'}
        placeholder={placeHolder}
        value={field.value}
        onChange={handleChange}
      />
    </FormField>
  );
};

export { InvoiceNumber };
