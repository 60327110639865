import React from 'react';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { MenuItem } from '@mui/material';
import { useFlags } from '@workmotion/feature-flags-library';
import { useUserIdentity } from '@workmotion/frontend-auth-library';
import { EditIcon, Spinner } from 'workmotion-design-system';

import { en } from 'localization';
import {
  InvoicesResponse,
  InvoiceSubmissionStatus,
} from 'types/invoice-submission';
import {
  actionMenuItemIconStyles,
  actionMenuItemStyles,
} from 'work-statement/styled-components';

const {
  invoiceSubmission: {
    table: {
      actionItems: {
        preview,
        downloadInvoice,
        adjust,
        readyForReview,
        transferHistory,
      },
    },
  },
} = en;

const ActionMenuItems: React.FC<{
  invoice: InvoicesResponse;
  openDetailsModal: () => void;
  openAdjustInvoiceModal: () => void;
  openTransferHistoryDrawer: () => void;
  openConfirmReadyForReviewModal: () => void;
  handleDownloadInvoice: () => void;
  isDownloadInvoiceLoading: boolean;
}> = ({
  invoice,
  openDetailsModal,
  openAdjustInvoiceModal,
  openTransferHistoryDrawer,
  openConfirmReadyForReviewModal,
  handleDownloadInvoice,
  isDownloadInvoiceLoading,
}) => {
  const { avengers2850NiumIntegrationTransactions } = useFlags();

  const {
    userIdentityInfo: { isAdmin, isHrManager, isCompanyAdmin, isContractor },
  } = useUserIdentity();

  const isHrUser = isHrManager || isCompanyAdmin;

  const { status } = invoice;

  const PreviewMenuItem = () => (
    <MenuItem
      data-testid="invoice-submission-preview-option"
      sx={actionMenuItemStyles}
      onClick={openDetailsModal}
    >
      <VisibilityIcon style={actionMenuItemIconStyles} />

      {preview}
    </MenuItem>
  );

  const DownloadInvoiceMenuItem = () => (
    <MenuItem
      data-testid="invoice-submission-download-invoice-option"
      sx={actionMenuItemStyles}
      onClick={handleDownloadInvoice}
    >
      {isDownloadInvoiceLoading ? (
        <Spinner size={15} style={{ marginInlineEnd: '0.5rem' }} />
      ) : (
        <DownloadRoundedIcon style={actionMenuItemIconStyles} />
      )}

      {downloadInvoice}
    </MenuItem>
  );

  const TransferHistoryMenuItem = () => (
    <MenuItem
      data-testid="invoice-submission-transfer-history-option"
      sx={actionMenuItemStyles}
      onClick={openTransferHistoryDrawer}
    >
      <DescriptionRoundedIcon style={actionMenuItemIconStyles} />

      {transferHistory}
    </MenuItem>
  );

  if (isAdmin) {
    return (
      <>
        <PreviewMenuItem />

        <DownloadInvoiceMenuItem />
      </>
    );
  }

  if (isHrUser) {
    return (
      <>
        <PreviewMenuItem />

        <DownloadInvoiceMenuItem />

        {avengers2850NiumIntegrationTransactions && <TransferHistoryMenuItem />}
      </>
    );
  }

  if (isContractor) {
    return (
      <>
        <PreviewMenuItem />

        <DownloadInvoiceMenuItem />

        {(status === InvoiceSubmissionStatus.DRAFT ||
          status === InvoiceSubmissionStatus.REJECTED) && (
          <MenuItem
            data-testid="invoice-submission-adjust-option"
            sx={actionMenuItemStyles}
            onClick={openAdjustInvoiceModal}
          >
            <EditIcon style={actionMenuItemIconStyles} />

            {adjust}
          </MenuItem>
        )}

        {(status === InvoiceSubmissionStatus.DRAFT ||
          status === InvoiceSubmissionStatus.REJECTED) && (
          <MenuItem
            data-testid="invoice-ready-for-review-option"
            sx={actionMenuItemStyles}
            onClick={openConfirmReadyForReviewModal}
          >
            <CheckCircleRoundedIcon style={actionMenuItemIconStyles} />

            {readyForReview}
          </MenuItem>
        )}

        {avengers2850NiumIntegrationTransactions && <TransferHistoryMenuItem />}
      </>
    );
  }

  return null;
};

export default ActionMenuItems;
