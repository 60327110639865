import React, { useState } from 'react';

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import DoneIcon from '@mui/icons-material/Done';
import EmailIcon from '@mui/icons-material/Email';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import TagIcon from '@mui/icons-material/Tag';
import { useUserIdentity } from '@workmotion/frontend-auth-library';
import { palette } from 'workmotion-design-system';

import { StyledIconContainer } from 'contractors/styled-components';
import {
  StyledContact,
  StyledContractorInfo,
  StyledSideInfoTitle,
} from 'contractors/view-contractor/shared-styled-components';
import { en } from 'localization';
import { ContractorResponse } from 'types/contractor';

import { StyledSideCard } from './contractor-info-card.styles';

interface ContractorInfoCardProps {
  contractorInfo: ContractorResponse;
}

export const ContractorInfoCard: React.FC<ContractorInfoCardProps> = ({
  contractorInfo: { contractorNumber, companyName, email, mobile },
}) => {
  const {
    contractor: {
      viewContractor: { infoSidebar },
    },
  } = en;

  const [isCopied, setIsCopied] = useState(false);

  const {
    userIdentityInfo: { isContractor },
  } = useUserIdentity();

  const handleCopy = (message: string) => {
    navigator.clipboard.writeText(message);
    setIsCopied(true);

    const timeout = setTimeout(() => {
      setIsCopied(false);
    }, 2000);

    return () => clearTimeout(timeout);
  };

  return (
    <StyledSideCard>
      <StyledSideInfoTitle>{infoSidebar.ContractorInfo}</StyledSideInfoTitle>

      {contractorNumber && (
        <StyledContractorInfo>
          <TagIcon
            style={{
              color: palette.greyscale.UCLABlue,
              fontSize: '1rem',
            }}
          />

          <StyledContact style={{ marginLeft: '0.5rem' }}>
            {contractorNumber}
          </StyledContact>

          <span>
            {!isContractor ? (
              isCopied ? (
                <StyledIconContainer
                  style={{ pointerEvents: 'none', marginLeft: '0.5rem' }}
                >
                  <DoneIcon
                    style={{
                      color: palette.primary.genericViridian,
                      fontSize: '1rem',
                    }}
                  />
                </StyledIconContainer>
              ) : (
                <StyledIconContainer
                  style={{ marginLeft: '0.5rem' }}
                  onClick={() => handleCopy(contractorNumber)}
                >
                  <FileCopyIcon
                    style={{
                      color: palette.primary.genericViridian,
                      fontSize: '1rem',
                    }}
                  />
                </StyledIconContainer>
              )
            ) : null}
          </span>
        </StyledContractorInfo>
      )}

      {companyName && (
        <StyledContractorInfo>
          <BusinessCenterIcon
            style={{
              color: palette.greyscale.UCLABlue,
              fontSize: '1rem',
            }}
          />

          <StyledContact style={{ marginLeft: '0.5rem' }}>
            {companyName}
          </StyledContact>
        </StyledContractorInfo>
      )}

      {mobile && (
        <StyledContractorInfo>
          <LocalPhoneIcon
            style={{
              color: palette.greyscale.UCLABlue,
              fontSize: '1rem',
            }}
          />

          <StyledContact style={{ marginLeft: '0.5rem' }}>
            {mobile}
          </StyledContact>
        </StyledContractorInfo>
      )}

      {email && (
        <StyledContractorInfo>
          <EmailIcon
            style={{
              color: palette.greyscale.UCLABlue,
              fontSize: '1rem',
            }}
          />

          <StyledContact style={{ marginLeft: '0.5rem' }}>
            {email}
          </StyledContact>
        </StyledContractorInfo>
      )}
    </StyledSideCard>
  );
};
