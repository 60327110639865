import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const StyledSideCard = styled.div({
  borderRadius: '1rem',
  background: palette.secondary.white,
  padding: '1.5rem',
  '@media(min-width:1200px)': {
    width: '100%',
  },
});
