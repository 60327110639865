import { object as yupObject } from 'yup';

import { en } from 'localization';
import { InputControlBase, ControlBase } from 'types/control-form';

import { inputValidation } from './input';
import { requiredValidation } from './required';
import { textAreaValidation } from './text-area';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const componentValidation = (
    component: ControlBase
    // eslint-disable-next-line consistent-return
) => {
    const { componentType, required, enabled } = component;

    const {
        validationMessages: {
            dynamicFields: {
                fieldRequired,
                maxLength,
                minNumber,
                maxNumber,
                validNumber,
                invalidValue,
                textAreaMaxLength,
            },
        },
    } = en;

    const minNumberConstraint = component.constraints?.find(
        constraint => constraint.min !== undefined
    )?.min;

    const maxNumberConstraint = component.constraints?.find(
        constraint => constraint.max !== undefined
    )?.max;

    const maxTextLengthConstraint = component.constraints?.find(
        constraint => constraint.maxLength !== undefined
    )?.maxLength;

    const minTextLengthConstraint = component.constraints?.find(
        constraint => constraint.minLength !== undefined
    )?.minLength;

    const inputComponent = <InputControlBase>component;

    if (enabled) {
        return yupObject()
            .test('field-required', `${fieldRequired}`, val =>
                requiredValidation.call(
                    this,
                    required,
                    componentType,
                    val,
                    component as any
                )
            )
            .test('text-input-max-length', `${maxLength}`, val =>
                inputValidation.textInputMaxLength(
                    componentType,
                    val,
                    inputComponent
                )
            )
            .test(
                'input-text-max-length',
                `Maximum length is ${maxTextLengthConstraint} characters`,
                val =>
                    inputValidation.inputTextMaxLength(
                        componentType,
                        val,
                        inputComponent,
                        maxTextLengthConstraint
                    )
            )
            .test(
                'input-text-min-length',
                `Minimum length is ${minTextLengthConstraint} characters`,
                val =>
                    inputValidation.inputTextMinLength(
                        componentType,
                        val,
                        inputComponent,
                        minTextLengthConstraint
                    )
            )

            .test(
                'number-min-number',
                `${minNumber} ${minNumberConstraint}`,
                val =>
                    inputValidation.minNumberValidation(
                        val,
                        inputComponent.type,
                        minNumberConstraint as unknown as number
                    )
            )
            .test(
                'number-max-number',
                `${maxNumber} ${maxNumberConstraint}`,
                val =>
                    inputValidation.maxNumberValidation(
                        val,
                        inputComponent.type,
                        maxNumberConstraint as unknown as number
                    )
            )
            .test('number-input-valid-number', `${validNumber}`, val =>
                inputValidation.validNumber(
                    componentType,
                    val,
                    inputComponent,
                    required
                )
            )

            .test('input-regex-expression', invalidValue, function (val) {
                return inputValidation.regexExpression.call(
                    this,
                    componentType,
                    val,
                    inputComponent
                );
            })
            .test('text-area-max-length', `${textAreaMaxLength}`, val =>
                textAreaValidation.maxLength(componentType, val)
            );
    }
};

export { componentValidation };
