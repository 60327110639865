import React from 'react';

import EastRoundedIcon from '@mui/icons-material/EastRounded';
import { useUserIdentity } from '@workmotion/frontend-auth-library';
import { useNavigate } from 'react-router-dom';
import { Avatar, palette } from 'workmotion-design-system';

import { InvoicesDetailsResponse } from 'types/invoice-submission';
import { WorkStatementDetails } from 'types/work-statement';
import {
  avatarStyles,
  ContractorCardContainer,
  ContractorInfoContainer,
} from 'work-statement/styled-components';

const ContractorCard: React.FC<{
  data: InvoicesDetailsResponse | WorkStatementDetails;
  contractorId?: string;
}> = ({ data, contractorId }) => {
  const {
    userIdentityInfo: { isContractor },
  } = useUserIdentity();

  const navigate = useNavigate();

  if (isContractor || !data?.contractorId || contractorId) return null;

  return (
    <ContractorCardContainer
      onClick={() => navigate(`/contractors/${data.contractorId}/basic-info`)}
    >
      <ContractorInfoContainer>
        <Avatar
          text={{ placeholder: data.contractorName }}
          avatarProps={{
            sx: {
              ...avatarStyles,
              height: '2.5rem',
              width: '2.5rem',
            },
          }}
          size="small"
        />

        <div>
          <h2>{data.contractorName}</h2>

          <p>{data.contractorJobTitle}</p>
        </div>
      </ContractorInfoContainer>

      <EastRoundedIcon
        sx={{ color: palette.greyscale.UCLABlue, fontSize: '1rem' }}
      />
    </ContractorCardContainer>
  );
};

export default ContractorCard;
