import React, { useCallback } from 'react';

import { DatePicker } from 'workmotion-design-system';

import { getDateFromIso, getFormattedIsoDate } from 'helpers/get-date-utils';
import { en } from 'localization';

import { DatePickerWrapper } from '../start-date-filter';

interface EndDateFilterProps {
  value: string;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  startDate: string;
  dateFilterMissingField: boolean;
}

const {
  invoiceSubmission: {
    table: {
      endDateFilter: { placeHolder },
    },
  },
} = en;

const EndDateFilter: React.FC<EndDateFilterProps> = ({
  value,
  setEndDate,
  startDate,
  dateFilterMissingField,
}) => {
  const dateChange = useCallback(
    (date: Date) => {
      setEndDate(getFormattedIsoDate(date) || '');
    },
    [setEndDate]
  );

  return (
    <DatePickerWrapper data-has-error={dateFilterMissingField && !value}>
      <DatePicker
        value={getDateFromIso(value)}
        onChange={dateChange}
        dataCy={'end-due-date-filter'}
        placeholder={placeHolder}
        minDate={getDateFromIso(startDate)}
        hasError={dateFilterMissingField && !value}
      />
    </DatePickerWrapper>
  );
};

export { EndDateFilter };
