const ALL_QUERY_PARAM = 'all';
const DEFAULT_CURRENT_PAGE = '1';
const DEFAULT_PAGE_SIZE = 10;

const SUPPORTED_LISTING_PARAMS = ['page', 'size', 'orderBy', 'direction'];
const DEFAULT_MOBILE_PAGE_SIZE = 20;

const workStatementFilters = [
  'contractorId',
  'contractorName',
  'status',
  'paymentType',
  ...SUPPORTED_LISTING_PARAMS,
];

const invoiceSubmissionFilters = [
  'contractorId',
  'companyId',
  'status',
  'requestStartDate',
  'requestEndDate',
  'dueStartDate',
  'dueEndDate',
  'invoiceNumber',
  ...SUPPORTED_LISTING_PARAMS,
];

const contractorFilters = [
  'contractorId',
  'contractorName',
  'contractStartDate',
  'contractEndDate',
  'countryIsoCode',
  'companyId',
  'status',
  'isLive',
  'includeState',
  ...SUPPORTED_LISTING_PARAMS,
];

export {
  DEFAULT_PAGE_SIZE,
  SUPPORTED_LISTING_PARAMS,
  DEFAULT_CURRENT_PAGE,
  ALL_QUERY_PARAM,
  DEFAULT_MOBILE_PAGE_SIZE,
  workStatementFilters,
  invoiceSubmissionFilters,
  contractorFilters,
};
