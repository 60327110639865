import React from 'react';

import {
  Avatar,
  CountryFlag,
  InfoBadge,
  palette,
} from 'workmotion-design-system';

import { ContractorResponse } from 'types/contractor';

import {
  StyledAvatarContainer,
  StyledChipsContainer,
  StyledProfileCard,
} from './profile-card.styles';

export const InfoCard: React.FC<{
  contractorInfo: ContractorResponse;
}> = ({ contractorInfo }) => {
  const name = `${contractorInfo?.firstName ?? ''} ${
    contractorInfo?.lastName ?? ''
  }`.trim();

  return (
    <StyledProfileCard>
      <StyledAvatarContainer>
        {(name !== '' || name === null) &&
          contractorInfo?.contractInfo?.jobTitle && (
            <Avatar
              avatarProps={{
                'data-avatar-size': 'compact',
                sx: {
                  width: 'fit-content',
                  height: '2.5rem',
                  minWidth: '2.5rem',
                  minHeight: '2.5rem',
                  padding: '.5rem',
                  backgroundColor: palette.secondary.softMint,
                  color: palette.semantic.persianGreen,
                },
              }}
              text={{
                title: name,
                subtitle: contractorInfo?.contractInfo?.jobTitle,
              }}
              attributes={{
                title: {
                  style: {
                    color: palette.primary.genericViridian,
                    fontWeight: 600,
                  },
                } as any,
              }}
            />
          )}

        <div>
          <CountryFlag
            countryIsoCode={contractorInfo?.address?.countryIsoCode}
            customStyledFlagIcon={{
              width: '1.5rem',
              height: '1.5rem',
              borderRadius: '50%',
              marginRight: '0.4rem',
            }}
            labelStyles={{
              fontSize: '0.875rem',
              display: 'contents',
              color: palette.greyscale.persianBlue,
            }}
          />
        </div>
      </StyledAvatarContainer>

      <StyledChipsContainer>
        <InfoBadge
          color={'blue'}
          customChipStyle={{
            borderRadius: '0.75rem',
          }}
          label="CONTRACTOR"
        />

        <InfoBadge
          color={'green'}
          label={contractorInfo?.status?.replace(/_/g, ' ')}
          customChipStyle={{
            borderRadius: '0.75rem',
            borderColor: 'transparent',
          }}
        />
      </StyledChipsContainer>
    </StyledProfileCard>
  );
};
