import React from 'react';

import { PreviewStatusLabel } from 'invoice-submission/invoice-submission-list/status-label';
import { en } from 'localization';
import { InvoiceSubmissionStatus } from 'types/invoice-submission';
import {
  DetailsModalField,
  DetailsModalLabel,
} from 'work-statement/styled-components';

const {
  workStatement: {
    workStatementDetailsModal: {
      fields: { status: statusLabel, milestoneStatus },
    },
  },
} = en;

const StatusField: React.FC<{
  status: InvoiceSubmissionStatus;
  milestones?: boolean;
  isPerMilestone?: boolean;
}> = ({ status, milestones, isPerMilestone }) => {
  if (!status) return null;

  return (
    <DetailsModalField
      data-status-field={!milestones && !isPerMilestone}
      data-is-per-milestone={isPerMilestone}
    >
      <DetailsModalLabel>
        {isPerMilestone ? milestoneStatus : statusLabel}
      </DetailsModalLabel>

      <PreviewStatusLabel status={status} />
    </DetailsModalField>
  );
};

export default StatusField;
