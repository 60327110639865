import React, { useCallback } from 'react';

import { useField } from 'formik';
import { FormField, Select } from 'workmotion-design-system';

import { en } from 'localization';
import { LineItemTypesEnum } from 'types/invoice-submission';

const {
  invoiceSubmission: {
    adjustInvoice: {
      lineItemType: {
        placeHolder,
        label,
        options: { allowance, bonus, commission },
      },
    },
  },
} = en;

const lineItemTypeOptions = [
  {
    label: allowance,
    value: LineItemTypesEnum.ALLOWANCE,
  },
  {
    label: bonus,
    value: LineItemTypesEnum.BONUS,
  },
  {
    label: commission,
    value: LineItemTypesEnum.COMMISSION,
  },
];

const LineItemType: React.FC<{
  fieldName: string;
}> = ({ fieldName }) => {
  const [field, meta, helpers] = useField(fieldName);

  const showError = !!(meta.touched && meta.error);

  const onChange = useCallback(
    selectedValue => {
      if (selectedValue) {
        helpers.setValue(selectedValue);
      }
    },
    [helpers]
  );

  return (
    <FormField label={label} showError={showError} errorMessage={meta.error}>
      <Select
        {...field}
        options={lineItemTypeOptions}
        value={field.value}
        onChange={onChange}
        placeholder={placeHolder}
        attrs={{
          select: { id: 'adjust-invoice-line-item-type-select' },
          input: {
            id: 'adjust-invoice-line-item-type-input',
          },
        }}
      />
    </FormField>
  );
};

export { LineItemType };
