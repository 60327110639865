import React, { useCallback } from 'react';

import styled from 'styled-components';
import { DatePicker, palette } from 'workmotion-design-system';

import { getDateFromIso, getFormattedIsoDate } from 'helpers/get-date-utils';
import { en } from 'localization';

interface StartDateFilterProps {
  value: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  endDate: string;
  dateFilterMissingField: boolean;
}

const {
  invoiceSubmission: {
    table: {
      startDateFilter: { placeHolder },
    },
  },
} = en;

export const DatePickerWrapper = styled.div`
  &[data-has-error='true'] {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${palette.semantic.sunsetOrange} !important;
    }
  }
`;

const StartDateFilter: React.FC<StartDateFilterProps> = ({
  value,
  setStartDate,
  endDate,
  dateFilterMissingField,
}) => {
  const dateChange = useCallback(
    (date: Date) => {
      setStartDate(getFormattedIsoDate(date) || '');
    },
    [setStartDate]
  );

  return (
    <DatePickerWrapper data-has-error={dateFilterMissingField && !value}>
      <DatePicker
        value={getDateFromIso(value)}
        onChange={dateChange}
        dataCy={'start-due-date-filter'}
        data-testid={'start-due-date-filter'}
        placeholder={placeHolder}
        maxDate={getDateFromIso(endDate)}
      />
    </DatePickerWrapper>
  );
};

export { StartDateFilter };
