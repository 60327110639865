import React, { useCallback } from 'react';

import { Select, palette } from 'workmotion-design-system';

import { en } from 'localization';

export enum DateTypeFilterEnum {
  REQUEST = 'REQUEST',
  DUE = 'DUE',
}

const selectOptions = [
  { label: 'Request date', value: DateTypeFilterEnum.REQUEST },
  { label: 'Due date', value: DateTypeFilterEnum.DUE },
];

interface DateTypeFilterProps {
  value: string;
  setDateType: React.Dispatch<React.SetStateAction<DateTypeFilterEnum>>;
  dateFilterMissingField: boolean;
}

const {
  invoiceSubmission: {
    table: {
      dateTypeFilter: { placeHolder },
    },
  },
} = en;

const DateTypeFilter: React.FC<DateTypeFilterProps> = ({
  value,
  setDateType,
  dateFilterMissingField,
}) => {
  const onChange = useCallback(
    option => {
      setDateType(option);
    },
    [setDateType]
  );

  return (
    <Select
      sx={{
        lineHeight: '0.875rem',
        fontSize: '0.75rem',
        fontFamily: 'Work Sans',
        color: palette.greyscale.persianBlue,
        marginBottom: '1rem',
        padding: '0.5rem 0 0 1rem',
      }}
      options={selectOptions}
      value={value}
      onChange={onChange}
      attrs={{
        select: { id: 'date-type-select' },
        input: {
          id: 'date-type-input',
        },
      }}
      placeholder={placeHolder}
      showError={dateFilterMissingField && !value}
    />
  );
};

export { DateTypeFilter };
