import React from 'react';

import { Box, SxProps } from '@mui/material';

import { capitalizeFirstLetter } from 'helpers/capitalize-first-letter';
import { formatNumber } from 'helpers/number-formatting';
import { en } from 'localization';
import { InvoicesDetailsResponse } from 'types/invoice-submission';
import {
  DetailsModalField,
  DetailsModalLabel,
  DetailsModalValue,
} from 'work-statement/styled-components';

const {
  invoiceSubmission: {
    invoiceDetailsModal: {
      fields: { lineItemType, lineItemTitle, lineItemAmount },
    },
  },
} = en;

const lineItemContainer: SxProps = {
  marginBottom: '2rem',
  ':last-child': {
    marginBottom: 0,
  },
};

const LineItems: React.FC<{
  invoiceData: InvoicesDetailsResponse;
}> = ({ invoiceData }) => {
  if (!invoiceData?.lineItems?.length) return null;

  return invoiceData.lineItems.map((lineItem, index) => (
    <Box key={`${index}-line-item`} sx={lineItemContainer}>
      <DetailsModalField data-with-top-border>
        <DetailsModalLabel>{lineItemType}</DetailsModalLabel>

        <DetailsModalValue>
          {capitalizeFirstLetter(lineItem.type)}
        </DetailsModalValue>
      </DetailsModalField>

      <DetailsModalField>
        <DetailsModalLabel>{lineItemTitle}</DetailsModalLabel>

        <DetailsModalValue>{lineItem.title}</DetailsModalValue>
      </DetailsModalField>

      <DetailsModalField>
        <DetailsModalLabel>{lineItemAmount}</DetailsModalLabel>

        <DetailsModalValue data-is-amount>{`${formatNumber(lineItem?.amount)} ${
          invoiceData?.currency
        }`}</DetailsModalValue>
      </DetailsModalField>
    </Box>
  ));
};

export default LineItems;
