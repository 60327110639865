import React from 'react';

import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

import { formatBytes } from 'helpers/string-helpers';

import { DocumentLink } from './table-components/document-link';

const StyledExpenseDocumentItem = styled.div({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: 8,
});

const DocumentItem = ({ document }) => (
  <StyledExpenseDocumentItem data-testid="document-item">
    <DocumentLink
      href={document?.viewUrl}
      name={document?.fileName}
      size={formatBytes(document?.size)}
      dataCy="document-item-link"
    />

    {!!document?.downloadUrl && (
      <a
        href={document?.downloadUrl}
        target="_blank"
        rel="noopener noreferrer"
        data-testid="document-item-download-link"
      >
        <FileDownloadRoundedIcon
          sx={{
            color: palette.primary.genericViridian,
            fontSize: '1.1rem',
          }}
        />
      </a>
    )}
  </StyledExpenseDocumentItem>
);

export default DocumentItem;
