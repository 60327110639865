import React from 'react';

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { Box } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import { InfoTip, palette } from 'workmotion-design-system';

import { en } from 'localization';
import { InvoicesDetailsResponse } from 'types/invoice-submission';
import {
  PaymentFrequencyEnum,
  WorkStatementPaymentType,
} from 'types/work-statement';
import {
  AddMilestoneButton,
  DeleteMilestone,
  FieldsSeparator,
  FormContainer,
  MilestoneHeader,
  MilestoneHeaderContainer,
  TwoFieldInOneRow,
} from 'work-statement/styled-components';

import { Amount } from './amount';
import { AdjustInvoiceType } from './formik-values-setup';
import { WithLineItems } from './has-line-items';
import { HoursMinutesFields } from './hours-minutes-field';
import { InvoiceNumber } from './invoice-number';
import { LineItemTitle } from './line-item-title';
import { LineItemType } from './line-item-type';
import { MilestoneTitle } from './milestone-title';
import { ProjectName } from './project-name';
import { Rate } from './rate';
import { UploadDocument } from './upload-document';
import { Vat } from './vat';

const {
  invoiceSubmission: {
    adjustInvoice: {
      amount,
      lineItemTitle,
      lineItemAmount,
      lineItemType,
      addAnotherLineItemButtonText,
      invoiceFileDisclaimer,
      otherFilesDisclaimer,
      externalInvoiceDocument,
      otherDocument,
    },
  },
} = en;

const FormComponents: React.FC<{
  invoiceDetails: InvoicesDetailsResponse;
}> = ({ invoiceDetails }) => {
  const { values } = useFormikContext<AdjustInvoiceType>();

  const { currency, paymentCycle, paymentType } = invoiceDetails;

  const isMilestone = paymentCycle === PaymentFrequencyEnum.PER_MILESTONE;

  const isFixedTerm = paymentType === WorkStatementPaymentType.FIXED_TERM;

  const isHourlyBased = paymentType === WorkStatementPaymentType.HOURLY_BASED;

  return (
    <FormContainer>
      {isMilestone && <MilestoneTitle />}

      {!isFixedTerm && !isMilestone && <ProjectName />}

      <TwoFieldInOneRow>
        {isHourlyBased ? (
          <Rate currency={currency} />
        ) : (
          <Amount fieldName={amount.fieldName} currency={currency} />
        )}

        <Vat />
      </TwoFieldInOneRow>

      {isHourlyBased && <HoursMinutesFields />}

      <FieldsSeparator />

      <Box sx={{ marginBottom: '2rem' }}>
        <InfoTip
          data-testid={'invoice-external-document-disclaimer'}
          data-cy={'invoice-external-document-disclaimer'}
          tipType={'info'}
          text={invoiceFileDisclaimer}
        />
      </Box>

      <UploadDocument
        fieldName={externalInvoiceDocument.fieldName}
        title={externalInvoiceDocument.uploaderTitle}
        description={externalInvoiceDocument.uploaderDescription}
        dataCy="adjust-invoice-document-uploader"
      />

      <InvoiceNumber />

      <FieldsSeparator />

      <Box sx={{ marginBottom: '2rem' }}>
        <InfoTip
          data-testid={'invoice-other-document-disclaimer'}
          data-cy={'invoice-other-document-disclaimer'}
          tipType={'info'}
          text={otherFilesDisclaimer}
        />
      </Box>

      <UploadDocument
        fieldName={otherDocument.fieldName}
        title={otherDocument.uploaderTitle}
        description={otherDocument.uploaderDescription}
        dataCy="other-document-uploader"
        multiple
      />

      <FieldsSeparator />

      <WithLineItems />

      {values.hasLineItems && (
        <FieldArray name="lineItems">
          {({ remove, push }) => (
            <div>
              {values.lineItems.length > 0 &&
                values.lineItems.map((_, index) => (
                  <div key={index}>
                    <MilestoneHeaderContainer>
                      <MilestoneHeader>Line item {index + 1}</MilestoneHeader>

                      {values.lineItems.length > 1 && (
                        <DeleteMilestone
                          data-testid={`delete-line-item-${index + 1}-button`}
                          onClick={() => remove(index)}
                        >
                          <DeleteForeverRoundedIcon
                            sx={{
                              fontSize: '1.1rem',
                              color: palette.semantic.sunsetOrange,
                            }}
                          />
                        </DeleteMilestone>
                      )}
                    </MilestoneHeaderContainer>

                    <LineItemType
                      fieldName={`lineItems.${index}.${lineItemType.fieldName}`}
                    />

                    <LineItemTitle
                      fieldName={`lineItems.${index}.${lineItemTitle.fieldName}`}
                    />

                    <Amount
                      fieldName={`lineItems.${index}.${lineItemAmount.fieldName}`}
                      currency={currency}
                    />
                  </div>
                ))}

              <AddMilestoneButton
                data-testid="add-line-item-button"
                onClick={() =>
                  push({
                    [lineItemType.fieldName]: null,
                    [lineItemTitle.fieldName]: '',
                    [lineItemAmount.fieldName]: '',
                  })
                }
              >
                <AddCircleRoundedIcon
                  sx={{
                    fontSize: '1rem',
                    marginInlineEnd: '0.5rem',
                    color: palette.greyscale.UCLABlue,
                  }}
                />

                <span>{addAnotherLineItemButtonText}</span>
              </AddMilestoneButton>
            </div>
          )}
        </FieldArray>
      )}
    </FormContainer>
  );
};

export default FormComponents;
