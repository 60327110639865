import React from 'react';

import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import { palette } from 'workmotion-design-system';

import {
  StyledContact,
  StyledContractorInfo,
  StyledSideInfoTitle,
} from 'contractors/view-contractor/shared-styled-components';
import { en } from 'localization';
import { DirectManager } from 'types/contractor';

import { StyledSideCard } from './contractor-info-card.styles';

interface ManagerInfoCardProps {
  directManager: DirectManager;
}

export const ManagerInfoCard: React.FC<ManagerInfoCardProps> = ({
  directManager: { name, email },
}) => {
  const {
    contractor: {
      viewContractor: { infoSidebar },
    },
  } = en;

  return (
    <StyledSideCard>
      <StyledSideInfoTitle>
        {infoSidebar.assignedHRInfoSection.assignedHrManagerInfo}
      </StyledSideInfoTitle>

      <StyledContractorInfo>
        <PersonIcon
          style={{
            color: palette.greyscale.UCLABlue,
            fontSize: '1rem',
          }}
        />

        <StyledContact style={{ marginLeft: '0.5rem' }}>{name}</StyledContact>
      </StyledContractorInfo>

      <StyledContractorInfo>
        <EmailIcon
          style={{
            color: palette.greyscale.UCLABlue,
            fontSize: '1rem',
          }}
        />

        <StyledContact style={{ marginLeft: '0.5rem' }}>{email}</StyledContact>
      </StyledContractorInfo>
    </StyledSideCard>
  );
};
