import React, { useCallback, useMemo } from 'react';

import { useField } from 'formik';
import { useDropzone } from 'react-dropzone';
import {
  DocumentUploader,
  FILES_EXTENSIONS,
  FormField,
} from 'workmotion-design-system';

import readFileAsDataURL from 'helpers/read-file-as-data-url';
import { FilenamePreview } from 'shared-components/filename-preview';

interface UploadDocumentProps {
  fieldName: string;
  title: string;
  description: string;
  dataCy?: string;
  multiple?: boolean;
  filesCount?: number;
  required?: boolean;
}

export const UploadDocument: React.FC<UploadDocumentProps> = ({
  fieldName,
  title,
  description,
  dataCy,
  multiple = false,
  required = false,
  filesCount: count = 2,
}) => {
  const [{ value }, meta, { setValue }] = useField(fieldName);

  const filesCount = useMemo(() => {
    if (multiple) return count;

    return 1;
  }, [count, multiple]);

  const getBase64String = base64FullString => {
    const commaIndex = base64FullString?.indexOf(',');

    if (commaIndex !== -1) {
      return base64FullString.substring(commaIndex + 1);
    }

    return base64FullString;
  };

  const onDrop = useCallback(
    files => {
      if (files.length) {
        const processFiles = async () => {
          const base64FilesPromise = files.map(file =>
            readFileAsDataURL(file).then(data => ({
              fileContent: getBase64String(data),
              fileContentType: file.type,
              fileName: file.name,
            }))
          );

          const result = await Promise.all(base64FilesPromise);

          if (result.length) {
            let valuesArr = [];

            if (value?.length) {
              valuesArr = [...value, ...result];
            } else {
              valuesArr = result;
            }

            if (valuesArr.length > filesCount) {
              valuesArr = valuesArr.splice(
                valuesArr.length - filesCount,
                valuesArr.length
              );
            }

            setValue(valuesArr);
          }
        };

        processFiles();
      }
    },
    [filesCount, setValue, value]
  );

  const onDeleteFile = (name: string) => {
    const newValues = value?.filter(file => {
      if (file?.fileName) {
        return file.fileName !== name;
      }

      return file.name !== name;
    });

    setValue(newValues);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    accept: FILES_EXTENSIONS as any,
  });

  return (
    <div data-testid={`${fieldName}-upload-document`}>
      <FormField
        label=""
        required
        showError={!!(meta.touched && meta.error)}
        errorMessage={meta.error}
      >
        <DocumentUploader
          rootProps={getRootProps()}
          inputProps={getInputProps()}
          title={title}
          description={description}
          required={required}
          dataCy={{
            documentUploader: dataCy,
            uploaderInput: `${dataCy}-input`,
          }}
          attrs={{
            type: 'button',
          }}
        />

        <FilenamePreview onDeleteFile={onDeleteFile} files={value || []} />
      </FormField>
    </div>
  );
};
