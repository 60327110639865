import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const ViewProfileContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});

export const StyledBack = styled.div({
  height: '4rem',
  display: 'flex',
  alignItems: 'center',
  color: palette.greyscale.UCLABlue,
  gap: '0.5rem',
  fontSize: '0.75rem',
  fontWeight: '500',
  cursor: 'pointer',
  width: 'fit-content',
});

export const StyledBodyContainer = styled.div({
  display: 'flex',
  gap: '1.5rem',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@media(min-width:1200px)': {
    flexDirection: 'row',
  },
});

export const StyledMainBody = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  '@media(min-width:1200px)': {
    width: '75%',
  },
});

export const StyledSideBody = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  '@media(min-width:1200px)': {
    width: '25%',
    alignItems: 'end',
  },
});
