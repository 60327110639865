import { SxProps } from '@mui/material';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const TableHeaderContainer = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1.5rem',
  background: palette.secondary.white,
  borderRadius: '1rem 1rem 0 0',
  zIndex: 1,
});

export const FiltersAndActions = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const avatarStyles: SxProps = {
  color: palette.semantic.persianGreen,
  height: '2rem',
  width: '2rem',
  fontSize: '0.75rem',
  lineHeight: '0.875rem',
  backgroundColor: palette.secondary.softMint,
};

export const ContractorListField = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const SideMenuContainer = styled.div({
  padding: '0 2rem 2rem 2rem',
});

export const StyledInfoBox = styled.div({
  padding: '1rem 0',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  fontSize: '.875rem',
  lineHeight: '1.375rem',
  color: palette.greyscale.UCLABlue,
  borderBottom: `1px solid ${[palette.greyscale.antiFlashWhite]}`,
  '&:last-child': {
    borderBottom: 'none',
  },
  'p, ol': {
    margin: '0px !important',
  },
  ol: {
    paddingLeft: '1rem !important',
  },
});

export const HeaderCellWithIcon = styled.div({
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'center',
});

export const PreviewPaymentTypeLabel = styled.b({
  color: palette.greyscale.persianBlue,
  fontWeight: 600,
  fontSize: '0.875rem',
});

export const filtersSelectStyles: SxProps = {
  lineHeight: '0.875rem',
  fontSize: '0.75rem',
  fontFamily: 'Work Sans',
  color: palette.greyscale.persianBlue,
  padding: '0.5rem 0 0 1rem',
  borderRadius: '0.75rem',
};

export const actionMenuItemStyles: SxProps = {
  fontSize: '0.75rem',
  fontFamily: 'Work Sans',
  color: palette.greyscale.UCLABlue,
  padding: '.68rem 1rem',
};

export const actionMenuItemIconStyles = {
  marginInlineEnd: '0.5rem',
  color: palette.greyscale.UCLABlue,
  fontSize: '1rem',
};

export const ContractorCardContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '0.5rem',
  width: '100%',
});

export const ContractorInfoContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  h2: {
    fontSize: '1rem',
    fontWeight: 600,
    color: palette.primary.genericViridian,
    marginBottom: '0.5rem',
  },
  p: {
    fontSize: '0.75rem',
    color: palette.greyscale.UCLABlue,
  },
});

export const StyledActionsContainer = styled.div({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  gap: '0.5rem',
  padding: '0.25rem',
});

export const StyledIconContainer = styled.div({
  borderRadius: '0.25rem',
  cursor: 'pointer',
  width: '1rem',
  height: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  svg: {
    color: palette.greyscale.UCLABlue,
    fontSize: '1rem',
  },
  '&:hover': {
    background: palette.tertiary.aquaHaze,
  },
  '&:hover svg': {
    color: palette.primary.genericViridian,
  },
});
