import React, { useCallback } from 'react';

import { Modal, Spinner } from 'workmotion-design-system';

import { capitalizeFirstLetter } from 'helpers/capitalize-first-letter';
import { formatDateInUTC, formatHoursAndMinutes } from 'helpers/get-date-utils';
import { en } from 'localization';
import { useGetInvoiceSubmissionById } from 'networking/contractor-api/contractor-network-requests';
import ContractorCard from 'shared-components/contractor-card';
import { InvoiceSubmissionStatus } from 'types/invoice-submission';
import { WorkStatementPaymentType } from 'types/work-statement';
import {
  DetailsModalField,
  DetailsModalLabel,
  DetailsModalValue,
  ModalSpinnerWrapper,
} from 'work-statement/styled-components';

import DetailsModalFooter from './details-modal-footer';
import InvoiceDocuments from './invoice-documents';
import LineItems from './line-items';
import StatusField from './status-field';
import { formatNumber } from '../../helpers/number-formatting';

const {
  invoiceSubmission: {
    invoiceDetailsModal: {
      modalTitle,
      fields: {
        invoiceId,
        requestDate,
        submissionDate,
        paymentType,
        paymentCycle,
        ProjectName,
        rate,
        hours,
        vat,
        amount,
        workStatementAmount,
        rejectionReason,
      },
    },
  },
} = en;

type ModalProps = {
  isDetailsModalOpen: boolean;
  setIsDetailsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedInvoiceSubmission: string;
  setSelectedInvoiceSubmission: React.Dispatch<React.SetStateAction<string>>;
  setIsAdjustInvoiceModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsReadyForReviewModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsTransferHistoryDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRejectionReasonModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const InvoiceDetailsModal: React.FC<ModalProps> = ({
  isDetailsModalOpen,
  setIsDetailsModalOpen,
  selectedInvoiceSubmission,
  setSelectedInvoiceSubmission,
  setIsAdjustInvoiceModalOpen,
  setIsReadyForReviewModalOpen,
  setIsTransferHistoryDrawerOpen,
  setIsRejectionReasonModalOpen,
}) => {
  const handelCloseModal = useCallback(() => {
    setIsDetailsModalOpen(false);
    setSelectedInvoiceSubmission(null);
  }, [setIsDetailsModalOpen, setSelectedInvoiceSubmission]);

  const handelOpenAdjustModal = useCallback(() => {
    setIsDetailsModalOpen(false);
    setIsAdjustInvoiceModalOpen(true);
  }, [setIsDetailsModalOpen, setIsAdjustInvoiceModalOpen]);

  const handelOpenReadyForReviewModal = useCallback(() => {
    setIsDetailsModalOpen(false);
    setIsReadyForReviewModalOpen(true);
  }, [setIsDetailsModalOpen, setIsReadyForReviewModalOpen]);

  const handelOpenTransferHistoryDrawer = useCallback(() => {
    setIsDetailsModalOpen(false);
    setIsTransferHistoryDrawerOpen(true);
  }, [setIsDetailsModalOpen, setIsTransferHistoryDrawerOpen]);

  const handelOpenRejectionReasonModal = useCallback(() => {
    setIsDetailsModalOpen(false);
    setIsRejectionReasonModalOpen(true);
  }, [setIsDetailsModalOpen, setIsRejectionReasonModalOpen]);

  const { data, isLoading } = useGetInvoiceSubmissionById(
    selectedInvoiceSubmission
  );

  const isHourlyBased =
    data?.paymentType === WorkStatementPaymentType.HOURLY_BASED;

  const isProjectBased =
    data?.paymentType === WorkStatementPaymentType.PROJECT_BASED;

  return (
    <Modal
      isOpen={isDetailsModalOpen}
      closeModal={handelCloseModal}
      data-testid="invoice-details-modal"
      header={modalTitle}
      dataCy={{ modalHeader: 'invoice-details-modal-title' }}
    >
      {isLoading ? (
        <ModalSpinnerWrapper>
          <Spinner />
        </ModalSpinnerWrapper>
      ) : (
        <>
          <ContractorCard data={data} />

          <StatusField status={data?.status as InvoiceSubmissionStatus} />

          {data?.rejectionReason && (
            <DetailsModalField data-multi-rows data-with-bottom-border>
              <DetailsModalLabel data-multi-rows>
                {rejectionReason}
              </DetailsModalLabel>

              <DetailsModalValue>{data?.rejectionReason}</DetailsModalValue>
            </DetailsModalField>
          )}

          <DetailsModalField>
            <DetailsModalLabel>{invoiceId}</DetailsModalLabel>

            <DetailsModalValue>{data?.invoiceNumber}</DetailsModalValue>
          </DetailsModalField>

          {(isHourlyBased || isProjectBased) && (
            <DetailsModalField>
              <DetailsModalLabel>{ProjectName}</DetailsModalLabel>

              <DetailsModalValue>{data?.projectName}</DetailsModalValue>
            </DetailsModalField>
          )}

          <DetailsModalField>
            <DetailsModalLabel>{requestDate}</DetailsModalLabel>

            <DetailsModalValue>
              {formatDateInUTC(data?.requestDate, 'DATE')}
            </DetailsModalValue>
          </DetailsModalField>

          <DetailsModalField>
            <DetailsModalLabel>{submissionDate}</DetailsModalLabel>

            <DetailsModalValue>
              {formatDateInUTC(data?.submissionDate, 'DATE') || '-'}
            </DetailsModalValue>
          </DetailsModalField>

          <DetailsModalField>
            <DetailsModalLabel>{paymentType}</DetailsModalLabel>

            <DetailsModalValue>
              {capitalizeFirstLetter(
                data?.paymentType?.replace(/_+/g, '-').trim()
              )}
            </DetailsModalValue>
          </DetailsModalField>

          <DetailsModalField>
            <DetailsModalLabel>{paymentCycle}</DetailsModalLabel>

            <DetailsModalValue>
              {capitalizeFirstLetter(
                data?.paymentCycle?.replace(/_+/g, '-').trim()
              )}
            </DetailsModalValue>
          </DetailsModalField>

          <DetailsModalField>
            <DetailsModalLabel>
              {isHourlyBased ? rate : workStatementAmount}
            </DetailsModalLabel>

            <DetailsModalValue>{`${formatNumber(
              isHourlyBased ? data?.rate : data?.workStatementAmount
            )} ${data?.currency}`}</DetailsModalValue>
          </DetailsModalField>

          {isHourlyBased && (
            <DetailsModalField>
              <DetailsModalLabel>{hours}</DetailsModalLabel>

              <DetailsModalValue>
                {formatHoursAndMinutes(
                  data?.hours?.hours,
                  data?.hours?.minutes
                )}
              </DetailsModalValue>
            </DetailsModalField>
          )}

          <DetailsModalField>
            <DetailsModalLabel>{vat}</DetailsModalLabel>

            <DetailsModalValue>{`${data?.vat} %`}</DetailsModalValue>
          </DetailsModalField>

          <DetailsModalField>
            <DetailsModalLabel>{amount}</DetailsModalLabel>

            <DetailsModalValue data-is-amount>{`${formatNumber(
              data?.totalAmount
            )} ${data?.currency}`}</DetailsModalValue>
          </DetailsModalField>

          <InvoiceDocuments invoiceData={data} />

          <LineItems invoiceData={data} />

          <DetailsModalFooter
            status={data?.status as InvoiceSubmissionStatus}
            handelOpenAdjustModal={handelOpenAdjustModal}
            handelOpenReadyForReviewModal={handelOpenReadyForReviewModal}
            handelOpenTransferHistoryDrawer={handelOpenTransferHistoryDrawer}
            handelOpenRejectionReasonModal={handelOpenRejectionReasonModal}
            selectedInvoiceSubmission={selectedInvoiceSubmission}
            handelCloseModal={handelCloseModal}
          />
        </>
      )}
    </Modal>
  );
};

export default InvoiceDetailsModal;
