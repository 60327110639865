import React from 'react';

import { useField } from 'formik';
import { FormField, InputField } from 'workmotion-design-system';

import { en } from 'localization';

const {
  invoiceSubmission: {
    adjustInvoice: {
      rate: { label, placeHolder, fieldName },
    },
  },
} = en;

const Rate: React.FC<{
  currency: string;
}> = ({ currency }) => {
  const [field, meta, helpers] = useField(fieldName);

  return (
    <FormField
      label={label}
      showError={!!(meta.touched && meta.error)}
      errorMessage={meta.error}
    >
      <InputField
        {...field}
        attrs={{
          input: {
            'data-testid': 'adjust-invoice-rate-input',
            'data-cy': 'adjust-invoice-rate-input',
          },
        }}
        unit={currency}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          helpers.setValue(event.target.value)
        }
        placeholder={placeHolder}
        type="unit"
        adornmentPosition="end"
      />
    </FormField>
  );
};

export { Rate };
