import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useAccessToken } from 'helpers/use-access-token';
import { QueryParameters } from 'types';
import { GetInvoiceByIdParameters } from 'types/invoice-submission';
import { GetWorkStatementByIdParameters } from 'types/work-statement';

import {
  downloadInvoiceDocument,
  getAllContractorsClient,
  getContractorClient,
  getInvoiceById,
  getWorkStatementById,
  listAllContractorInvoices,
  listAllWorkStatements,
} from './contractor-client';

export const useListAllWorkStatements = () => {
  const { accessToken } = useAccessToken();

  const getAllWorkStatements = async (params: QueryParameters) =>
    listAllWorkStatements(accessToken?.accessToken, params);

  return { getAllWorkStatements };
};

export const useGetWorkStatementById = (selectedWorkStatement: string) => {
  const { accessToken } = useAccessToken();

  const getOneWorkStatement = async (params: GetWorkStatementByIdParameters) =>
    getWorkStatementById(accessToken?.accessToken, params);

  const { data, isLoading } = useQuery({
    queryKey: ['getWorkStatementById', selectedWorkStatement],
    queryFn: () =>
      getOneWorkStatement({ workStatementId: selectedWorkStatement }),
    enabled: !!selectedWorkStatement,
  });

  return { data, isLoading };
};

export const useGetAllContractors = () => {
  const { accessToken } = useAccessToken();

  const getAllContractors = useCallback(
    async (params: QueryParameters) =>
      getAllContractorsClient(accessToken?.accessToken, params),
    [accessToken?.accessToken]
  );

  return { getAllContractors };
};

export const useGetContractor = (contractorId: string) => {
  const { accessToken } = useAccessToken();

  const getContractor = async () =>
    getContractorClient(accessToken?.accessToken, contractorId);

  const { data, isLoading } = useQuery({
    queryKey: ['getContractor', contractorId],
    queryFn: getContractor,
    staleTime: 0,
  });

  return { data, isLoading };
};

export const useListAllInvoiceSubmission = getInvoiceSubmissionParams => {
  const { accessToken } = useAccessToken();

  const getAllInvoiceSubmissions = async (params: QueryParameters) =>
    listAllContractorInvoices(accessToken?.accessToken, params);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['invoiceSubmissionRecords', getInvoiceSubmissionParams],
    queryFn: () => getAllInvoiceSubmissions(getInvoiceSubmissionParams),
  });

  return { data, isLoading, isFetching };
};

export const useGetInvoiceSubmissionById = (selectedInvoice: string) => {
  const { accessToken } = useAccessToken();

  const getOneInvoiceSubmission = async (params: GetInvoiceByIdParameters) =>
    getInvoiceById(accessToken?.accessToken, params);

  const { data, isLoading } = useQuery({
    queryKey: ['getInvoiceSubmissionById', selectedInvoice],
    queryFn: () => getOneInvoiceSubmission({ invoiceId: selectedInvoice }),
    enabled: !!selectedInvoice,
  });

  return { data, isLoading };
};

export const useDownloadInvoiceUrl = (selectedInvoice: string) => {
  const { accessToken } = useAccessToken();

  const getInvoiceDocumentDownloadUrl = async (params: { invoiceId: string }) =>
    downloadInvoiceDocument(accessToken?.accessToken, params);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getInvoiceDocumentDownloadUrl', selectedInvoice],
    queryFn: () =>
      getInvoiceDocumentDownloadUrl({ invoiceId: selectedInvoice }),
    enabled: !!selectedInvoice,
  });

  return { data, isLoading, isFetching };
};
