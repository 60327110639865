import React from 'react';

import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

const Link = styled.a({
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
});

const LinkName = styled.span({
  color: palette.greyscale.persianBlue,
  fontSize: '0.875rem',
  overflow: 'hidden',
  minHeight: '0.95rem',
  maxWidth: 250,
  fontWeight: 400,
  fontFamily: 'Work Sans',
  textDecoration: 'none',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const DocumentSize = styled.span({
  marginLeft: '1rem',
  fontWeight: 300,
  fontSize: 12,
  fontFamily: 'Work Sans',
  color: palette.greyscale.UCLABlue,
});

interface DocumentLinkProps {
  href: string;
  name: string;
  size?: string;
  dataCy?: string;
  documents?: any[];
}

const DocumentLink: React.FC<DocumentLinkProps> = ({
  href,
  name,
  size,
  dataCy,
}) => {
  if (!name) return <>-</>;

  return (
    <Link
      href={href}
      target="_blank"
      rel="noreferrer noopener"
      data-cy="document-download-link"
      data-testid="document-link"
    >
      <DescriptionRoundedIcon
        sx={{
          color: palette.greyscale.UCLABlue,
          fontSize: '.9rem',
          marginInlineEnd: '.3rem',
        }}
      />

      <LinkName style={{ marginLeft: 4 }} data-cy={dataCy}>
        {name}
      </LinkName>

      {size && <DocumentSize>({size})</DocumentSize>}
    </Link>
  );
};

export { DocumentLink };
