import React from 'react';

import { useFlags } from '@workmotion/feature-flags-library';

import { PagesCardsInfo } from './page-card-info';
import { PageCardItem } from './page-card-item';
import { StyledMultiCardContainer } from './page-card-list.styles';

interface PageCardListProps {
  contractorId: string;
}

export const PageCardList: React.FC<PageCardListProps> = ({ contractorId }) => {
  const { avengers2986AddInvoiceSubmissionViewForTheContractorProfile } =
    useFlags();

  const filteredPagesCardsInfo = PagesCardsInfo.filter(card => {
    if (card.title === 'Invoice Submission') {
      return !!avengers2986AddInvoiceSubmissionViewForTheContractorProfile;
    }

    return true;
  });

  return (
    <StyledMultiCardContainer>
      {filteredPagesCardsInfo?.map(card => (
        <PageCardItem
          key={card.title}
          Icon={card.icon}
          title={card.title}
          subTitle={card.subTitle}
          contractorId={contractorId}
          path={card.path}
        />
      ))}
    </StyledMultiCardContainer>
  );
};
