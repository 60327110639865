import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { UsePaginationReturn } from 'helpers/use-pagination';
import { FiltersMenu } from 'shared-components/filters-menu';
import { QueryParameters } from 'types';

import { DateTypeFilter, DateTypeFilterEnum } from './date-type-filter';
import { EndDateFilter } from './end-date-filter';
import { StartDateFilter } from './start-date-filter';
import { StatusFilter, StatusFilterEnum } from './status-filter';

interface FiltersMenuProps {
  handleChangeFilters: UsePaginationReturn['handleChangeFilters'];
  removeFilters: UsePaginationReturn['removeFilters'];
  requestParams: QueryParameters;
}

const Filters: React.FC<FiltersMenuProps> = ({
  handleChangeFilters,
  removeFilters,
  requestParams: {
    requestStartDate: queryRequestStartDate,
    requestEndDate: queryRequestEndDate,
    dueStartDate: queryDueStartDate,
    dueEndDate: queryDueEndDate,
    status: queryStatus,
  },
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [dateType, setDateType] = useState<DateTypeFilterEnum>();

  const [startDate, setStartDate] = useState('');

  const [endDate, setEndDate] = useState('');

  const [status, setStatus] = useState<StatusFilterEnum>();

  const [dateFilterMissingField, setDateFilterMissingField] = useState(false);

  const isDateFilterMissingField = useMemo(
    () =>
      (startDate || endDate || dateType) && !(startDate && endDate && dateType),
    [dateType, endDate, startDate]
  );

  const applyFilters = useCallback(() => {
    if (isDateFilterMissingField) {
      setDateFilterMissingField(true);
    } else {
      let params = {};

      const additionalParamsToRemove = [];

      if (status === StatusFilterEnum.ALL) {
        additionalParamsToRemove.push('status');
      }

      if (dateType === DateTypeFilterEnum.REQUEST) {
        params = {
          ...params,
          requestStartDate: startDate,
          requestEndDate: endDate,
        };

        additionalParamsToRemove.push('dueStartDate', 'dueEndDate');
      }

      if (dateType === DateTypeFilterEnum.DUE) {
        params = { ...params, dueStartDate: startDate, dueEndDate: endDate };

        additionalParamsToRemove.push('requestStartDate', 'requestEndDate');
      }

      if (status) {
        params = { ...params, status };
      }

      handleChangeFilters(params);

      removeFilters(additionalParamsToRemove);

      setIsMenuOpen(false);
    }
  }, [
    isDateFilterMissingField,
    status,
    dateType,
    handleChangeFilters,
    removeFilters,
    startDate,
    endDate,
  ]);

  const resetFilters = useCallback(() => {
    setDateType(null);
    setStartDate('');
    setEndDate('');
    setStatus(null);
    setIsMenuOpen(false);
    setDateFilterMissingField(false);

    removeFilters([
      'requestStartDate',
      'requestEndDate',
      'dueStartDate',
      'dueEndDate',
      'status',
    ]);
  }, [removeFilters]);

  const getDateType = (requestStartDate: string, dueStartDate: string) => {
    if (requestStartDate) {
      return DateTypeFilterEnum.REQUEST;
    }

    if (dueStartDate) {
      return DateTypeFilterEnum.DUE;
    }

    return null;
  };

  const setSelectedValues = useCallback(() => {
    setDateType(getDateType(queryRequestStartDate, queryDueStartDate));
    setStartDate(queryRequestStartDate || queryDueStartDate);
    setEndDate(queryRequestEndDate || queryDueEndDate);
    setStatus(queryStatus as StatusFilterEnum);
  }, [
    queryDueEndDate,
    queryDueStartDate,
    queryRequestEndDate,
    queryRequestStartDate,
    queryStatus,
  ]);

  useEffect(() => {
    setSelectedValues();
  }, [setSelectedValues]);

  return (
    <FiltersMenu
      isOpen={isMenuOpen}
      setIsMenuOpen={setIsMenuOpen}
      onReset={resetFilters}
      onApply={applyFilters}
      onClose={setSelectedValues}
    >
      <StatusFilter setStatus={setStatus} value={status} />

      <DateTypeFilter
        value={dateType}
        setDateType={setDateType}
        dateFilterMissingField={dateFilterMissingField}
      />

      <StartDateFilter
        setStartDate={setStartDate}
        value={startDate}
        endDate={endDate}
        dateFilterMissingField={dateFilterMissingField}
      />

      <EndDateFilter
        setEndDate={setEndDate}
        value={endDate}
        startDate={startDate}
        dateFilterMissingField={dateFilterMissingField}
      />
    </FiltersMenu>
  );
};

export default Filters;
