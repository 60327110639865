import React from 'react';

import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';
import { palette } from 'workmotion-design-system';

import {
  StyledIconContainer,
  StyledRowContainer,
  StyledSingleCardContainer,
  StyledSubtitle,
  StyledTitle,
} from './page-card-item.styles';

interface PageCardItemProps {
  title: string;
  subTitle: string;
  contractorId: string;
  path: string;
  Icon: React.ElementType;
}

export const PageCardItem: React.FC<PageCardItemProps> = ({
  Icon,
  title,
  subTitle,
  contractorId,
  path,
}) => {
  const navigate = useNavigate();

  return (
    <StyledSingleCardContainer
      onClick={() => navigate(`/contractors/${contractorId}/${path}`)}
    >
      <StyledRowContainer>
        <StyledIconContainer>
          <Icon style={{ color: palette.primary.genericViridian }} />
        </StyledIconContainer>

        <EastIcon
          style={{
            color: palette.greyscale.UCLABlue,
            fontSize: '1rem',
          }}
        />
      </StyledRowContainer>

      <StyledTitle>{title}</StyledTitle>

      <StyledSubtitle>{subTitle}</StyledSubtitle>
    </StyledSingleCardContainer>
  );
};
