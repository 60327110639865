import React, { useCallback } from 'react';

import { Box } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { FormField, RadioGroup } from 'workmotion-design-system';

import { en } from 'localization';
import { radioGroupLabelStyle } from 'work-statement/styled-components';

import { AdjustInvoiceType } from './formik-values-setup';

const {
  invoiceSubmission: {
    adjustInvoice: {
      hasLineItem: {
        label,
        fieldName,
        options: { yes, no },
      },
      lineItems: { fieldName: lineItemsField },
      lineItemType,
      lineItemTitle,
      lineItemAmount,
    },
  },
} = en;

const withLineItemsOptions = [
  {
    label: yes,
    value: 'true',
  },
  {
    label: no,
    value: 'false',
  },
];

const WithLineItems = () => {
  const [field, meta, helpers] = useField(fieldName);
  const { setFieldValue } = useFormikContext<AdjustInvoiceType>();

  const showError = !!(meta.touched && meta.error);

  const onChange = useCallback(
    e => {
      helpers.setValue(e.target.value === 'true' ? true : false);

      if (e.target.value === 'true') {
        setFieldValue(lineItemsField, [
          {
            [lineItemType.fieldName]: '',
            [lineItemTitle.fieldName]: '',
            [lineItemAmount.fieldName]: '',
          },
        ]);
      } else {
        setFieldValue(lineItemsField, []);
      }
    },
    [helpers, setFieldValue]
  );

  return (
    <FormField label={label} showError={showError} errorMessage={meta.error}>
      <Box sx={radioGroupLabelStyle}>
        <RadioGroup
          {...field}
          options={withLineItemsOptions.map(elem => ({
            id: elem.value,
            name: elem.value,
            label: elem.label,
            value: elem.value,
            'data-cy': `${elem.label.toLowerCase()}-radio`,
          }))}
          theme="dark"
          value={field.value}
          onChange={onChange}
          data-testid={'adjust-invoice-has-line-item-radio'}
          row
        />
      </Box>
    </FormField>
  );
};

export { WithLineItems };
