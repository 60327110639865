import styled, { CSSObject } from 'styled-components';
import { palette } from 'workmotion-design-system';

export const sharedBorder = '1px solid #D9DCE3';

export const StyledSideInfoSection = styled.section({
  '.MuiChip-label': { color: 'unset' },

  '@media (max-width: 1200px)': {
    width: '100%',
    padding: '1.5rem 0',
    '&:last-of-type': {
      borderBottom: 'unset',
    },
  },
} as CSSObject);

export const StyledSideInfoTitle = styled.p({
  textAlign: 'left',
  letterSpacing: 0,
  color: palette.greyscale.persianBlue,
  opacity: 1,
  marginBottom: '1rem',
  fontWeight: 600,
  fontSize: '1rem',
  fontFamily: 'Work Sans',
});

export const StyledSideInfoSectionTitle = styled(StyledSideInfoTitle)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const StyledContractorInfo = styled.div({
  display: 'flex',
  alignItems: 'center',
  fontSize: 15,
  lineHeight: '22px',
  color: palette.greyscale.UCLABlue,
  marginBottom: '1rem',
});

export const StyledContact = styled.span({
  maxWidth: '14rem',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});
