import React from 'react';

import QueryBuilderRoundedIcon from '@mui/icons-material/QueryBuilderRounded';
import { Box } from '@mui/material';
import { useField } from 'formik';
import { FormField, InputField, palette } from 'workmotion-design-system';

import { en } from 'localization';
import { TwoFieldInOneRow } from 'work-statement/styled-components';

const ClockIcon = () => (
  <QueryBuilderRoundedIcon
    sx={{
      position: 'absolute',
      top: 11,
      right: 12,
      fontSize: '1.2rem',
      color: palette.greyscale.sharkMouth,
    }}
  />
);

const {
  invoiceSubmission: {
    adjustInvoice: {
      hours: {
        label: hoursLabel,
        fieldName: hoursFieldName,
        placeholder: hoursPlaceholder,
      },
      minutes: {
        label: minutesLabel,
        fieldName: minutesFieldName,
        placeholder: minutesPlaceholder,
      },
    },
  },
} = en;

const HoursMinutesFields = () => {
  const [hoursField, hoursMeta, hoursHelpers] = useField(hoursFieldName);

  const [minutesField, minutesMeta, minutesHelpers] =
    useField(minutesFieldName);

  return (
    <TwoFieldInOneRow>
      <FormField
        label={hoursLabel}
        showError={!!(hoursMeta.touched && hoursMeta.error)}
        errorMessage={hoursMeta.error}
      >
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <InputField
            {...hoursField}
            type="number"
            placeholder={hoursPlaceholder}
            onChange={e => hoursHelpers.setValue(e.target.value)}
            data-testid="submitted-hours-input"
          />

          <ClockIcon />
        </Box>
      </FormField>

      <FormField
        label={minutesLabel}
        showError={!!(minutesMeta.touched && minutesMeta.error)}
        errorMessage={minutesMeta.error}
      >
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <InputField
            {...minutesField}
            type="number"
            placeholder={minutesPlaceholder}
            onChange={e => minutesHelpers.setValue(e.target.value)}
            data-testid="submitted-minutes-input"
          />

          <ClockIcon />
        </Box>
      </FormField>
    </TwoFieldInOneRow>
  );
};

export { HoursMinutesFields };
