import { useCallback, useState } from 'react';

import {
  DropzoneInputProps,
  DropzoneRootProps,
  useDropzone,
  Accept,
} from 'react-dropzone';
import { toast } from 'react-toastify';
import { ALL_EXTENSIONS } from 'workmotion-design-system';

import { en } from 'localization';
import { useUploadContractorDocument } from 'networking/contractor-api/contractor-mutations';
import { RequestError } from 'types';
import { DocumentType } from 'types/contractor';

import { handleRequestError } from './handle-request-error';

interface UseUploadDocumentReturn {
  isUploading: boolean;
  rootProps: DropzoneRootProps;
  inputProps: DropzoneInputProps;
}

const {
  contractor: {
    viewContractor: {
      documents: {
        uploadDocumentSuccessMessage,
        uploadDocumentErrorMessage,
        uploadDocumentSizeErrorMessage,
      },
    },
  },
} = en;

const useUploadDocument = (
  docOwnerId: string | undefined,
  filesExtensions: string[],
  documentType: DocumentType,
  maxSize?: number,
  setErrors?: (errors: string) => void
): UseUploadDocumentReturn => {
  const { mutate: uploadDocument, isLoading } =
    useUploadContractorDocument(docOwnerId);

  const [isUploading, setUploading] = useState(false);

  const handleUploadDocument = useCallback(
    (file: File) => {
      if (docOwnerId) {
        uploadDocument(
          {
            documentType,
            file,
          },
          {
            onSuccess: () => {
              setUploading(false);

              toast(uploadDocumentSuccessMessage, {
                type: 'success',
              });
            },
            onError: (err: RequestError) => {
              setUploading(false);

              let errorMsg = uploadDocumentSizeErrorMessage;

              if (err?.response) {
                errorMsg = uploadDocumentErrorMessage;
              }

              handleRequestError(err, errorMsg);
            },
          }
        );
      }
    },
    [docOwnerId, documentType, uploadDocument]
  );

  const onDrop = useCallback(
    ([file]: File[]) => {
      if (file as File) {
        setUploading(true);
        handleUploadDocument(file);
      } else if (setErrors) {
        setErrors(uploadDocumentSizeErrorMessage);
      } else {
        handleRequestError(null, uploadDocumentErrorMessage);
      }
    },
    [handleUploadDocument, setErrors]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    maxSize,
    multiple: false,
    accept:
      (filesExtensions as unknown as Accept) ||
      (ALL_EXTENSIONS as unknown as Accept),
  });

  const rootProps = getRootProps({
    isDragActive,
    isDragAccept,
    isDragReject,
  });

  const inputProps = getInputProps();

  return {
    isUploading: isLoading || isUploading,
    rootProps,
    inputProps,
  };
};

export { useUploadDocument };
