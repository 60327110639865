import React from 'react';

import { useField } from 'formik';
import { FormField, InputField } from 'workmotion-design-system';

import { en } from 'localization';

const {
  invoiceSubmission: {
    adjustInvoice: {
      vat: { placeHolder, label },
    },
  },
} = en;

const Vat: React.FC = () => {
  const [field, meta, helpers] = useField('vat');

  const showError = !!(meta.touched && meta.error);

  return (
    <FormField label={label} showError={showError} errorMessage={meta.error}>
      <InputField
        {...field}
        type="unit"
        unit="%"
        adornmentPosition="end"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          helpers.setValue(event.target.value)
        }
        placeholder={placeHolder}
        attrs={{
          input: {
            'data-cy': 'adjust-invoice-vat-input',
            'data-testid': 'adjust-invoice-vat-input',
          },
        }}
      />
    </FormField>
  );
};

export { Vat };
