import { FormikValues } from 'formik';
import {
  string as yupString,
  number as yupNumber,
  object as yupObject,
  array as yupArray,
} from 'yup';

import { en } from 'localization';
import { InvoicesDetailsResponse } from 'types/invoice-submission';
import {
  PaymentFrequencyEnum,
  WorkStatementPaymentType,
} from 'types/work-statement';

const {
  invoiceSubmission: {
    adjustInvoice: {
      amount,
      rate,
      projectName,
      mileStoneTitle,
      vat,
      lineItems,
      hasLineItem,
      lineItemType,
      lineItemTitle,
      lineItemAmount,
      hours,
      minutes,
      externalInvoiceDocument,
      invoiceNumber,
      otherDocument,
    },
  },
} = en;

interface AdjustInvoiceType extends FormikValues {
  [key: string]: any;
}

const getAdjustInvoiceInitialValues: (
  invoiceDetails: InvoicesDetailsResponse
) => AdjustInvoiceType = invoiceDetails => ({
  [amount.fieldName]: invoiceDetails?.basicSalary || '',
  [rate.fieldName]: invoiceDetails?.rate || '',
  [vat.fieldName]: invoiceDetails?.vat || '',
  [hours.fieldName]: invoiceDetails?.hours?.hours || '',
  [externalInvoiceDocument.fieldName]: invoiceDetails?.invoice?.downloadUrl
    ? [invoiceDetails?.invoice]
    : [],
  [otherDocument.fieldName]: !!invoiceDetails?.optionalDocuments?.length
    ? invoiceDetails?.optionalDocuments
    : [],
  [invoiceNumber.fieldName]: invoiceDetails?.invoiceNumber || '',
  [minutes.fieldName]: invoiceDetails?.hours?.minutes || '',
  [projectName.fieldName]: invoiceDetails?.projectName || '',
  [mileStoneTitle.fieldName]: invoiceDetails?.milestoneTitle || '',
  [hasLineItem.fieldName]: invoiceDetails?.hasLineItems || false,
  [lineItems.fieldName]: invoiceDetails?.lineItems || [],
});

const adjustInvoiceSchema = (invoiceDetails: InvoicesDetailsResponse) => {
  const isMilestone =
    invoiceDetails?.paymentCycle === PaymentFrequencyEnum.PER_MILESTONE;

  const isHourlyBased =
    invoiceDetails?.paymentType === WorkStatementPaymentType.HOURLY_BASED;

  const isFixedTerm =
    invoiceDetails?.paymentType === WorkStatementPaymentType.FIXED_TERM;

  return yupObject().shape({
    [projectName.fieldName]:
      !isMilestone &&
      !isFixedTerm &&
      yupString().required(projectName.errorMessage),
    [mileStoneTitle.fieldName]:
      !!isMilestone && yupString().required(mileStoneTitle.errorMessage),
    [amount.fieldName]:
      !isHourlyBased &&
      yupNumber()
        .required(amount.errorMessage)
        .positive(amount.positiveValueErrorMessage),
    [rate.fieldName]:
      !!isHourlyBased &&
      yupNumber()
        .required(rate.errorMessage)
        .positive(rate.positiveValueErrorMessage),
    [vat.fieldName]: yupNumber()
      .required(vat.errorMessage)
      .positive(vat.positiveValueErrorMessage),
    [hours.fieldName]:
      !!isHourlyBased &&
      yupNumber()
        .integer(hours.holeNumberErrorMessage)
        .min(0, hours.positiveErrorMessage)
        .nullable()
        .test(
          'at-least-one',
          hours.noFilledFieldsErrorMessage,
          function (value) {
            const { minutes: minutesField } = this.parent;

            return !!value || !!minutesField;
          }
        ),
    [minutes.fieldName]:
      !!isHourlyBased &&
      yupNumber()
        .integer(minutes.holeNumberErrorMessage)
        .min(0, minutes.positiveErrorMessage)
        .max(59, minutes.maxValueErrorMessage)
        .nullable(),
    [externalInvoiceDocument.fieldName]: yupArray().min(
      1,
      externalInvoiceDocument.errorMessage
    ),
    [lineItems.fieldName]: yupArray().of(
      yupObject().shape({
        [lineItemType.fieldName]: yupString()
          .nullable()
          .required(lineItemType.errorMessage),
        [lineItemTitle.fieldName]: yupString().required(
          lineItemTitle.errorMessage
        ),
        [lineItemAmount.fieldName]: yupNumber()
          .required(lineItemAmount.errorMessage)
          .positive(lineItemAmount.positiveValueErrorMessage),
      })
    ),
  });
};

export { getAdjustInvoiceInitialValues, adjustInvoiceSchema };

export type { AdjustInvoiceType };
