import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const SignatureBannerCardContainer = styled.div({
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: `solid 1px ${palette.primary.genericViridian}`,
  borderRadius: '.5rem',
  backgroundColor: palette.tertiary.aquaHaze,
  padding: '1.5rem',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '1.5rem',
    padding: '1.5rem 1rem',
  },
});

export const SignatureBannerCardTitle = styled.h1({
  color: palette.primary.genericViridian,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontWeight: 600,
});

export const SignatureBannerCardContent = styled.h2({
  color: palette.greyscale.persianBlue,
  fontSize: '.875rem',
  lineHeight: '1.375rem',
  marginBottom: '1rem',
  fontWeight: 400,
});

export const CardContentBox = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1rem',
  '@media (max-width: 600px)': {
    maxWidth: '100%',
  },
};

export const ButtonsBox = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  margin: '0 auto',
  gap: '1rem',

  '@media(max-width:600px)': {
    flexDirection: 'column',
    gap: '1rem',
  },
};
