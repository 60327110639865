import React from 'react';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { palette } from 'workmotion-design-system';

import {
  StyledContact,
  StyledContractorInfo,
  StyledSideInfoTitle,
} from 'contractors/view-contractor/shared-styled-components';
import { formatDateInUTC } from 'helpers/get-date-utils';
import { en } from 'localization';
import { ContractInfo } from 'types/contractor';

import { StyledSideCard } from './contractor-info-card.styles';

interface ContractInfoCardProps {
  contractInfo: ContractInfo;
}

export const ContractInfoCard: React.FC<ContractInfoCardProps> = ({
  contractInfo: { startDate, endDate },
}) => {
  const {
    contractor: {
      viewContractor: { infoSidebar },
    },
  } = en;

  return (
    <StyledSideCard>
      <StyledSideInfoTitle>{infoSidebar.contractInfo}</StyledSideInfoTitle>

      {startDate && (
        <StyledContractorInfo>
          <CalendarMonthIcon
            style={{
              color: palette.greyscale.UCLABlue,
              fontSize: '1rem',
            }}
          />

          <StyledContact style={{ marginLeft: '0.5rem' }}>
            {`Start : ${(startDate && formatDateInUTC(startDate)) || '-'}`}
          </StyledContact>
        </StyledContractorInfo>
      )}

      {endDate && (
        <StyledContractorInfo>
          <CalendarMonthIcon
            style={{
              color: palette.greyscale.UCLABlue,
              fontSize: '1rem',
            }}
          />

          <StyledContact style={{ marginLeft: '0.5rem' }}>
            {`End : ${(endDate && formatDateInUTC(endDate)) || '-'}`}
          </StyledContact>
        </StyledContractorInfo>
      )}
    </StyledSideCard>
  );
};
