import DescriptionIcon from '@mui/icons-material/Description';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonIcon from '@mui/icons-material/Person';
import TimerIcon from '@mui/icons-material/Timer';
interface PageCardInfo {
  path: string;
  title: string;
  subTitle: string;
  icon: React.ElementType;
}

export const PagesCardsInfo: PageCardInfo[] = [
  {
    path: 'basic-info',
    title: 'Information',
    subTitle: 'Contains basic, contracts, and banking info.',
    icon: PersonIcon,
  },
  {
    path: 'documents',
    title: 'Documents',
    subTitle: 'Contains documents like contracts.',
    icon: DescriptionIcon,
  },
  {
    path: 'work-statements',
    title: 'Work Statements',
    subTitle: 'Contains all the details about work statement.',
    icon: TimerIcon,
  },
  {
    path: 'basic-info',
    title: 'Invoice Submission',
    subTitle: 'Contains details about invoices submission.',
    icon: MonetizationOnIcon,
  },
];
