import React, { createContext, useContext, useState, useEffect } from 'react';

import { useUserIdentity } from '@workmotion/frontend-auth-library';
import { useParams } from 'react-router-dom';

import { useGetContractor } from 'networking/contractor-api/contractor-network-requests';
import { ContractorResponse } from 'types/contractor';

export interface ViewContractorContextValue {
  contractorInfo: ContractorResponse;
  isLoading?: boolean;
}

const defaultValue: ViewContractorContextValue = {
  contractorInfo: undefined,
  isLoading: false,
};

const ViewContractorContext =
  createContext<ViewContractorContextValue>(defaultValue);

const ViewContractorContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const {
    userIdentityInfo: { isContractor, userInfo },
  } = useUserIdentity();

  const currentUserId = userInfo?.employeeId as string;

  const { id } = useParams<{
    id?: string;
  }>();

  const contractorId = !isContractor ? id : currentUserId;

  const { data, isLoading } = useGetContractor(contractorId);

  const [contractorInfo, setContractorInfo] = useState<ContractorResponse>();

  useEffect(() => {
    if (data) {
      setContractorInfo(data);
    }
  }, [data]);

  return (
    <ViewContractorContext.Provider
      value={{
        contractorInfo,
        isLoading,
      }}
    >
      {children}
    </ViewContractorContext.Provider>
  );
};

const useViewContractorContext = (): ViewContractorContextValue =>
  useContext(ViewContractorContext);

export {
  useViewContractorContext,
  ViewContractorContextProvider,
  ViewContractorContext,
};
