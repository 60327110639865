import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const StyledMultiCardContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  borderRadius: '1rem',
  background: palette.secondary.white,
  padding: '1.5rem',
  gap: '1.5rem',
  justifyContent: 'space-between',
});
