import React, { useCallback } from 'react';

import { useField } from 'formik';
import { FormField, InputField } from 'workmotion-design-system';

import { en } from 'localization';

const {
  invoiceSubmission: {
    adjustInvoice: {
      lineItemTitle: { label, placeHolder },
    },
  },
} = en;

const LineItemTitle: React.FC<{
  fieldName: string;
}> = ({ fieldName }) => {
  const [field, meta, helpers] = useField(fieldName);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      helpers.setValue(event.target.value);
    },
    [helpers]
  );

  return (
    <FormField
      showError={!!(meta.touched && meta.error)}
      errorMessage={meta.error}
      label={label}
    >
      <InputField
        {...field}
        type={'text'}
        onChange={handleChange}
        value={field.value}
        placeholder={placeHolder}
        attrs={{
          input: {
            'data-testid': 'adjust-invoice-line-item-title-input',
            'data-cy': 'adjust-invoice-line-item-title-input',
          },
        }}
      />
    </FormField>
  );
};

export { LineItemTitle };
