import React from 'react';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box } from '@mui/material';
import { ALL_EXTENSIONS, Button } from 'workmotion-design-system';

import { en } from 'localization';
import { UploadDocument } from 'shared-components/documents-tab/upload-document';

import ApprovalIcon from './ApprovalIcon.svg';
import {
  SignatureBannerCardContainer,
  SignatureBannerCardContent,
  CardContentBox,
  ButtonsBox,
  SignatureBannerCardTitle,
} from './styled-components';

interface ConfirmationBannerProps {
  contractorId: string;
}

const {
  contractor: {
    viewContractor: {
      signatureBanner: { title, content, action },
    },
  },
} = en;

export const SignatureBanner: React.FC<ConfirmationBannerProps> = ({
  contractorId,
}) => (
  <SignatureBannerCardContainer>
    <div>
      <ApprovalIcon />
    </div>

    <Box sx={CardContentBox}>
      {<SignatureBannerCardTitle> {title}</SignatureBannerCardTitle>}

      <SignatureBannerCardContent>{content}</SignatureBannerCardContent>

      <Box sx={ButtonsBox}>
        <UploadDocument
          docOwnerId={contractorId}
          filesExtensions={ALL_EXTENSIONS}
          documentType="EMPLOYMENT_CONTRACT"
          CustomButton={() => (
            <Button
              style={{
                fontSize: '0.75rem',
                fontFamily: 'Work Sans',
                fontWeight: 500,
              }}
              onClick={() => {}}
              data-testid="signature-banner-btn"
              prefixIcon={() => <FileUploadIcon style={{ fontSize: '1rem' }} />}
            >
              {action}
            </Button>
          )}
        />
      </Box>
    </Box>
  </SignatureBannerCardContainer>
);
