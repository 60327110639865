import styled from 'styled-components';
import { palette } from 'workmotion-design-system';

export const StyledProfileCard = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  borderRadius: '1rem',
  background: palette.secondary.white,
  padding: '1.5rem',
});

export const StyledAvatarContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

export const StyledChipsContainer = styled.div({
  display: 'flex',
  gap: '1rem',
});
